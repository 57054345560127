import React from "react";

import AvatarChoose from "./editProfile/avatarChoose";
import AvatarUpload from "./editProfile/avatarUpload";
import EmailSettings from "./editProfile/emailSettings";
import DeleteAccount from "./editProfile/deleteAccount";
import EditDetailsForm from "./editProfile/editDetailsForm";
import UserPasswordForm from "./editProfile/userPasswordForm";
import UserGuildSettings from "./guild/userGuildSettings";

export default class EditProfile extends React.Component {
	render() {
		return(
			<div className="row justify-content-center">
				<div
					className="col-12 text-left"
					//style={{backgroundColor: "white"}}
				>
					<div id="title" className="mt-4 ml-3">
						Profile
					</div>
				</div>
				<div className="col-12 col-md-6 col-lg-5 col-xl-5 row edit-profile-left-column h-100">
					<>
						<div className="col-12">
							{this.props.info.type === process.env.REACT_APP_USER_TYPE ? (
								<AvatarChoose
									changeImage={this.changeImage}
									keys={this.props.keys}
									fetchNewInfo={this.props.fetchNewInfo}
									info={this.props.info}
								/>
							) : (
								<AvatarUpload
									info={this.props.info}
									refresh={this.props.fetchNewInfo}
								/>
							)}
						</div>

						{(
							this.props.info.type === process.env.REACT_APP_USER_TYPE
							|| this.props.info.type === process.env.REACT_APP_GAMEDEV_TYPE
							|| this.props.info.type === process.env.REACT_APP_PROVIDER_TYPE
						) && (
							<div className="col-12 mt-5">
								<div className="my-2" style={{fontWeight: "bold"}}>
									Guild
								</div>
								<UserGuildSettings/>
							</div>
						)}
					</>
				</div>
				<div className="col-12 col-md-6 col-lg-7 col-xl-7 row edit-profile-right-column">
					<EditDetailsForm
						info={this.props.info}
						fetchNewInfo={this.props.fetchNewInfo}
					/>

					<div className="edit-profile-password-box col-12 col-lg-10">
						<UserPasswordForm
							info={this.props.info}
							fetchNewInfo={this.props.fetchNewInfo}
						/>
					</div>

					<div className="edit-profile-email-settings-box col-12 col-lg-10">
						<EmailSettings
							info={this.props.info}
							fetchNewInfo={this.props.fetchNewInfo}
						/>
					</div>

					<div className="delete-account-message-to-support col-12 col-lg-10">
						<DeleteAccount
							info={this.props.info}
							fetchNewInfo={this.props.fetchNewInfo}
						/>
					</div>
				</div>
			</div>
		);
	}
}
