/*
    This file contains the functions that we use to communicate with the QUDO servers.

    This was created to work with the data structure we use in the replies of our API endpoints in order
    to improve the message/error handling from what we had previously.

    The functions created in this file are supposed to easily replace previously existing Axios calls.
*/

import axios from 'axios';

export default class QUDOServer {
    static get(url, config) {
        return new Promise((resolve, reject) => {
            axios.get(url, config ? config : null)
            .then(response => {
                const data = response.data;

                if(data.success) {
                    resolve(data);
                } else {
                    reject(data);
                }
            })
            .catch(error => {
                const errorMessage = 'There was a problem connecting to the QUDO server';

                const response = {
                    success: false,
                    errorMessage: errorMessage,
                    data: null
                }

                reject(response);
            });
        });
    }

    static post(url, data, config) {
        return new Promise((resolve, reject) => {
            axios.post(url, data ? data : null, config ? config : null)
            .then(response => {
                const data = response.data;

                if(data.success) {
                    resolve(data);
                } else {
                    reject(data);
                }
            })
            .catch(error => {
                const errorMessage = 'There was a problem connecting to the QUDO server';

                const response = {
                    success: false,
                    errorMessage: errorMessage,
                    data: null
                }

                reject(response);
            });
        });
    }
}
