import React, {useState, useEffect} from "react";
import axios from 'axios';
import {useHistory} from "react-router-dom";
import {Modal} from "react-bootstrap";
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import useWindowSize from "../../../helpers/useWindowSize";
import QUDOServer from "../../../helpers/QUDOServerConnection";

import {YoutubeSlide, customRenderItem, customRenderThumb} from '../react-responsive-carousel/YoutubeSlide';
import {DropdownHighscoreRewards} from '../../components/forms/dropdowns';
import './styles/generic.scss';
import StarRating from 'react-star-ratings';
import {useSelector} from "react-redux";
import {MessageModal} from "../../components/modals/modals.js";
import ReactTooltip from "react-tooltip";
import ConfirmModal from "./confirmModal/confirmModal";
import GamedevDetailsModal from "./GamedevDetailsModal";
import ToggleSwitch from '../Switch/ToggleSwitch'
import { DropdownInput } from '../forms/dropdowns';


const fallBack = require("../../../images/fallbackAvatar.png");

const appStoreButton = require("../../../images/marketplaces/app_store_en.svg");
const googlePlayStoreButton = require("../../../images/marketplaces/google_play_store_en.svg");
const steamStoreButton = require("../../../images/marketplaces/steam_store_en.svg");
const itchioStoreButton = require("../../../images/marketplaces/itchio_store_en.svg");
const genericDownloadButton = require("../../../images/marketplaces/generic_download_en.svg");

const medal = require("../../../images/definitive/icn-medal-updated.png");
const trophy = require("../../../images/definitive/icn-trophy-gold.png");
const product = require("../../../images/definitive/icn-product.png");

const unavailableBanner = require("../../../images/banners/QUDO_TemporarilyUnavailable.png");
const comingSoonBanner = require("../../../images/banners/QUDO_ComingSoon.png");
const alphaBanner = require("../../../images/banners/QUDO_Alpha.png");
const betaBanner = require("../../../images/banners/QUDO_Beta.png");
const newBanner = require("../../../images/banners/QUDO_New.png");

const gold = require("../../../images/definitive/icn-trophy-gold.png");
const silver = require("../../../images/definitive/icn-trophy-silver.png");
const bronze = require("../../../images/definitive/icn-trophy-bronze.png");
const avatar = require("../../../images/definitive/icon-avatar.png");

const helpButton = require('../../../images/definitive/help-button-1.png');

function MarketPlaces(props) {
	let buttonList = [];

	if(Array.isArray(props.marketplaces)) {
		props.marketplaces.forEach(marketplace => {
			if(marketplace.platform === 'ios') {
				buttonList.push(
					<StoreButton
						key={marketplace.url + marketplace.platform}
						classes="horizontal"
						data={{
							//link: `https://${ProcessLink(marketplace.url)}`,
							link: marketplace.url,
							img: appStoreButton,
							alt: "App Store"
						}}
					/>
				);
			} else if(marketplace.platform === 'android') {
				buttonList.push(
					<StoreButton
						key={marketplace.url + marketplace.platform}
						classes="horizontal"
						data={{
							//link: `https://${ProcessLink(marketplace.url)}`,
							link: marketplace.url,
							img: googlePlayStoreButton,
							alt: "Google Play"
						}}
					/>
				);
			} else if(marketplace.platform === 'steam') {
				buttonList.push(
					<StoreButton
						key={marketplace.url + marketplace.platform}
						classes="horizontal"
						data={{
							//link: `https://${ProcessLink(marketplace.url)}`,
							link: marketplace.url,
							img: steamStoreButton,
							alt: "Steam Store"
						}}
					/>
				);
			} else if(marketplace.platform === 'itchio') {
				buttonList.push(
					<StoreButton
						key={marketplace.url + marketplace.platform}
						classes="horizontal"
						data={{
							//link: `https://${ProcessLink(marketplace.url)}`,
							link: marketplace.url,
							img: itchioStoreButton,
							alt: "itchi.io"
						}}
					/>
				);
			} else /* if(marketplace.platform === 'pc' || marketplace.platform === 'other') */ {
				buttonList.push(
					<StoreButton
						key={marketplace.url + marketplace.platform}
						classes="horizontal"
						data={{
							//link: `https://${ProcessLink(marketplace.url)}`,
							link: marketplace.url,
							img: genericDownloadButton,
							alt: "Download"
						}}
					/>
				);
			}
		});
	}

	//if(buttonList.length > 0) {
		return (
			<>
			<div className="section-title mt-3 mb-1">
				Available at:
			</div>
			<div>
				{buttonList.length > 0 ? buttonList.map(e => {
					return e;
				}) : (
					<div className={`edit-screen-text-area`} style={{whiteSpace: 'initial'}}>
						There are no marketplaces available at the moment
					</div>
				)}
			</div>
			</>
		);
	//} else {
	//	return null;
	//}
}

function StoreButton(props) {
	return (
		<div className={`gamedetails-store-button ${props.classes ? props.classes : ''}`}>
			<a 
				target="_blank"
				rel="noopener noreferrer"
				href={props.data.link}
			>
				<img
					alt={props.data.alt}
					src={props.data.img}
					className="gamedetails-store-image"
				/>
			</a>
		</div>
	);
}

/*
function ProcessLink(link) {
	link = link.replace("https://", "");
	link = link.replace("http://", "");
	return link;
}
*/

export default function GameDetailsModal(props) {
	const history = useHistory();
	const windowSize = useWindowSize();

	const [gamedev, setGamedev] = useState(null);
	const [displayName, setDisplayName] = useState("");
	const [website, setWebsite] = useState("");
	const [description, setDescription] = useState("");
	//const [showFullDescription, setShowFullDescription] = useState(true);
	const [marketplaces, setMarketPlaces] = useState(null);
	const [releaseDate, setReleaseDate] = useState("");
	const [comingSoon, setComingSoon] = useState(false);
	const [gameState, setGameState] = useState(null);
	const [newGame, setNewGame] = useState(null);

	const [genres, setGenres] = useState([]);

	const [mediaImages, setMediaImages] = useState([]);
	const [mediaVideos, setMediaVideos] = useState([]);

	// these seem unused but they are used by eval(), so don't delete them ok?
	// let me just write them here so react doesn't complain while building :D
	const [achievements, setAchievements] = useState();
	const [highscores, setHighscores] = useState();
	const [products, setProducts] = useState();
	//const [bugTesting, setBugTesting] = useState();

	//const [mostEffectivePlayers, setMostEffectivePlayers] = useState([]);
	const [activeHighscoreFilter, setActiveHighscoreFilter] = useState('');
	const [highscoreFilters, setHighscoreFilters] = useState([]);
	const [highscoreList, setHighScoreList] = useState([]);
	const [activePeriod, setActivePeriod] = useState('alltime');
	const [periodOptions, setPeriodOptions] = useState([]);
	const [rating, setRating] = useState();
	const [numberOfReviews, setNumberOfReviews] = useState();

	const [successModal, setSuccessModal] = useState(false);
	const [failureModal, setFailureModal] = useState(false);
	const [modalMessage, setModalMessage] = useState('');

	const [playerReview, setPlayerReview] = useState(0);
	const [newPlayerReview, setNewPlayerReview] = useState(0);

	const [showLabel, setShowLabel] = useState(false);
	const [label, setLabel] = useState(null);
	const [altText, setAltText] = useState('');
	const [customStyle, setCustomStyle] = useState({});

	const [showGamedev, setShowGamedev] = useState('');

	const types = {
		achievements,
		highscores,
		products,
		//bugTesting
	}

	const [activeTab, setActiveTab] = useState("achievements");

	const game = props.game;
	const info = useSelector(state => state.info);
	const [gameBugSpotting, setGameBugSpotting] = useState();

	const [confirmRatingModal, setConfirmRatingModal] = useState(false);
	const [confirmRatingMessage, setConfirmRatingMessage] = useState('')

	let prepareHighscoreList_cancelTokenSource;

	useEffect(() => {
		const url = new URL(window.location.href);
		const params = new URLSearchParams(url.search);

		//console.log(url, params);

		const achievements = params.get('achievements');
		const highscores = params.get('highscores');
		const products = params.get('products');

		if(achievements) {
			setActiveTab('achievements');
		} else if(highscores) {
			setActiveTab('highscores');
		} else if(products) {
			setActiveTab('products');
		}
		/*
		else if(bugTesting) {
			setActiveTab('bug spotting');
		}
		*/

		if(achievements || highscores || products) {
			setTimeout(() => {
				var divToScrollTo = document.getElementById('RewardsSection');

				if(divToScrollTo) {
					divToScrollTo.scrollIntoView();
				}
			}, 2500);
		}

		setPeriodOptions([{key:'alltime',label:'All-Time'}, {key:'weekly' ,label:'Weekly'}]);
	}, []);

	useEffect(() => 
	{
		if(game) 
		{
			QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/reviews`,
			{
				game: game
			})
			.then(response => 
			{
				const currentUserReview = response.data.find(review => review.player._id === info._id);

				if (currentUserReview)
				{
					setPlayerReview(currentUserReview.rating);
				}
			})
			.catch(error => 
			{
				setPlayerReview();
			});
		}
	}, [game]);

	/*
	useEffect(() => {
		if(document.getElementById('game_description')) {
			const descriptionHeigth = document.getElementById('game_description').clientHeight;

			if(descriptionHeigth > 350) {
				setShowFullDescription(false);
			} else {
				setShowFullDescription(true);
			}
		}
	}, [description]);
	*/

	useEffect(() =>
	{
		if(game) 
		{
			QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/gameinfo?gamename=${game}`)
			.then(response => 
			{
				setRating(response.data.rating);
				setNumberOfReviews(response.data.reviewNr);
			});
		}
	}, [playerReview])

	useEffect(() => 
	{
		if(game) {
			QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/gameinfo?gamename=${game}`)
			.then(response => {

				if(response.data.gamedev) {
					setGamedev(response.data.gamedev);
				}

				setWebsite(response.data.website);
				setDescription(response.data.description);
				setDisplayName(response.data.display_name);

				setComingSoon(response.data.comingSoon);
				setReleaseDate(response.data.estimatedReleaseDate ? response.data.estimatedReleaseDate : 'To be announced');

				setNewGame(response.data.newGame);
				setGameState(response.data.gameState);

				if(Object.keys(response.data.marketplaces).length > 0) {
					setMarketPlaces(response.data.marketplaces);
				}

				setGenres(response.data.genres);

				setMediaImages(response.data.media_images);
				setMediaVideos(response.data.media_videos);

				setAchievements(response.data.achievements);
				// setAchievements(
				// 	response.data.achievements.sort((a, b) => {
				// 		if(a.name < b.name) { return -1; }
				// 		if(a.name > b.name) { return 1; }
				// 		return 0;
				// 	})
				// );

				setHighscores(response.data.highscores);
				// setHighscores(
				// 	response.data.highscores.sort((a, b) => {
				// 		if(a.name < b.name) { return -1; }
				// 		if(a.name > b.name) { return 1; }
				// 		return 0;
				// 	})
				// );

				setProducts(response.data.products);
				// setProducts(
				// 	response.data.products.sort((a, b) => {
				// 		if(a.name < b.name) { return -1; }
				// 		if(a.name > b.name) { return 1; }
				// 		return 0;
				// 	})
				// );

				setRating(response.data.rating);
				setNumberOfReviews(response.data.reviewNr);

				setGameBugSpotting(response.data.bugSpotting);

				/*
				QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/game/topplayers?game=${game}`)
				.then(response => {
					//console.log(response);
					setMostEffectivePlayers(response.data.mostEffectivePlayers);
				})
				.catch(error => {
					//console.log(error);
					setMostEffectivePlayers([]);
				});
				*/
			})
			.catch(error => {
				//console.log(error);
				cleanOnHide();
			});
		}
	}, [game]);

	useEffect(() => {
		if(comingSoon) {
			setLabel(comingSoonBanner);
			setAltText('Game coming soon...');
			setCustomStyle({maxWidth: '275px'});
		} else {
			if (newGame) {
				setLabel(newBanner);
				setAltText('New game');
			} else if(gameState === 'unavailable') {
				setLabel(unavailableBanner);
				setAltText('Game is temporarily unavailable');
			} else if(gameState === 'alpha') {
				setLabel(alphaBanner);
				setAltText('Game in alpha state');
			} else if(gameState === 'beta') {
				setLabel(betaBanner);
				setAltText('Game in beta state');
			} // else if(gameState === 'stable') {
			// 	if(newGame) {
			// 		setLabel(newBanner);
			// 		setAltText('New game');
			// 	}
			// }
		}
	}, [comingSoon, gameState, newGame]);

	useEffect(() =>
	{
		if (highscores && highscores.length > 0)
		{
			chooseHighscoreFilters();
		}
	}, [highscores]);

	useEffect(() =>
	{
		if (highscores && highscores.length > 0)
		{
			prepareHighscoreList();
		}
	}, [activeHighscoreFilter, activePeriod]);

	const chooseHighscoreFilters = () =>
	{
		let filters = [];

		highscores.forEach(highscore =>
		{
			filters.push(highscore.name);
		});

		setActiveHighscoreFilter(filters[0]);
		setHighscoreFilters(filters);
	}

	const prepareHighscoreList = () =>
	{
		const highscoreToShow = highscores.find(highscore => highscore.name === activeHighscoreFilter);

		if(highscoreToShow) {
			if(prepareHighscoreList_cancelTokenSource)
				prepareHighscoreList_cancelTokenSource.cancel('Operation canceled due to new request.');
			prepareHighscoreList_cancelTokenSource = axios.CancelToken.source();
			QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/highscore/leaderboard`, {
				game: game,
				alias: highscoreToShow.alias,
				period: activePeriod
			}, {
				cancelToken: prepareHighscoreList_cancelTokenSource.token
			})
			.then(response => {
				setHighScoreList(response.data);
			})
			.catch(error => {
				if (axios.isCancel(error)) {
					return;
				}
				setHighScoreList([]);
			});
		}
	}

	function cleanOnHide() {
		props.setShow(null);
		history.push(window.location.pathname);

		setWebsite(null);
		setDescription(null);
		setDisplayName(null);
		setMarketPlaces(null);

		setMediaImages([]);
		setMediaVideos([]);

		setAchievements(null);
		setHighscores(null);
		setProducts(null);

		setHighScoreList([]);
		setHighscoreFilters([]);
		setActiveHighscoreFilter('');
		setActiveTab('achievements');

		setComingSoon(null);
		setGameState(null);
		setNewGame(null);
		setShowLabel(false);
		setLabel(null);
		setAltText('');
		setCustomStyle({});
		//setMostEffectivePlayers(null);

		setPlayerReview(0);
		setRating();
		setNumberOfReviews();
		setSuccessModal(false);
		setFailureModal(false);
	}

	function renderGalleryItems(videos, images) {
		const response = [];

		if(Array.isArray(videos)) {
			videos.map(video => {
				response.push(
					<YoutubeSlide key={video.videoLink} url={video.videoLink}/>
				);
			});
		}

		if(Array.isArray(images)) {
			images.map(image => {
				response.push(
					<img
						key={image.imageID}
						alt="Game artwork/screenshot"
						src={`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?type=game&imageID=${image.imageID}`}
						onError={(i) => (i.target.src = fallBack)}
						className="img-fluid"
						style={{width: "initial", maxHeight: "450px"}}
					/>
				);
			});
		}

		//console.log(response);
		return response;
	}

	/*
	const getTopPlayers = () =>
	{
		return ( 
			<div className="row">
                <div id="most_effective_row" className="col-12">
                    <>
					<div className="custom-header mb-1 mt-3">
						<strong>Best Players</strong> (last month)
					</div>
                        {
                        mostEffectivePlayers
						&& Array.isArray(mostEffectivePlayers)
						&& mostEffectivePlayers.length > 0
						? mostEffectivePlayers.slice(0, 10).map((i, index) => {
                            return (
                                <div key={i.username}>
                                    <div key={i.username} className="top-players-card custom align-top d-flex " style={{ alignItems: "center" }}>
										<div className="col-1 col-lg-1 flex-column justify-content-center">
                                            <div className="text-wrap" style={{display: 'inline-block'}}>
                                                <div>
                                                    #{index + 1}
                                                </div>
											</div>
                                        </div>
										<div className="col-2 col-lg-2 position-relative">
											<img
												src={`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyuser?user=${i.username}`}
												className="rounded-circle position-absolute"
												id="top-players-games"
												alt="games avatar"
												key={index}
											/>
										</div>
                                        <div className="col-3 col-lg-5 flex-column justify-content-center text-wrap">
                                            <div className="text-wrap" style={{display: 'inline-block'}}>
                                            <div id="top-players-info-bottom" className="text-wrap username">
                                                {i.display_name ? i.display_name : i.username}
                                            </div>
                                        </div>
                                    </div>
									<div className="col-4 col-lg-3 flex-column justify-content-center text-wrap">
                                        <div className="text-wrap" style={{display: 'inline-block'}}>
                                        	<div className="text-wrap points">
                                                Points
                                            </div>
                                            <div className="text-wrap numbers">
                                                {Number(i.score).toFixed(0)}
                                            </div>
                                        </div>
                                    </div>
									<div className="col-2 col-lg-2 d-inline-block top-players-trophy">
                                        <img
                                            src={index + 1 <= 3 ? (index + 1 === 1 ? gold : (index + 1 === 2 ? silver : bronze)) : medal}
                                            className="icon-32px"
                                            alt="player-icon"
                                        />
                                    </div>
                                </div>
                            </div>
							);
						}) : (
							<div className="edit-screen-text-area mt-2 mb-4">
								Last month's best players are currently unavailable for this game
							</div>
						)}
					</>
				</div>
        	</div>
		)
	}
	*/


	const periodDropdown = () => {
		return (
			<div>
			<DropdownInput
				label='Period'
				initial='All-Time'
				set={setActivePeriod}
				choices={periodOptions}
				style={{maxHeight:'64px', marginBottom: '0px'}}
				/>
			</div>
		);
	}

	const getHighscores = () =>
	{
		return ( 
			<div className="row">
                <div id="most_effective_row" className="col-12">
                    <>
					<div className="custom-header mb-2 mt-2" style={{display:"flex", justifyContent:"space-between", alignItems:"flex-start"}}>
						<div className="col-12 col-sm-3 p-0 main-inner-title">Highscores</div>
						<div className="col-12 col-sm-3 p-0 m-0 " style={{display:"block"}}>
							{periodDropdown()}
						</div>
					</div>
					{
                    highscoreFilters
					&& Array.isArray(highscoreFilters)
					&& highscoreFilters.length > 0
					? (
						<>
						<div className="mt-1 mb-1">
							<DropdownHighscoreRewards
								id="qudo-now-dropdown"
								classes="dropdown-highscores"
								activeFilter={activeHighscoreFilter}
								set={setActiveHighscoreFilter}
								choices={highscoreFilters}
							/>
						</div>
                        {
                        highscoreList
						&& Array.isArray(highscoreList)
						&& highscoreList.length > 0
						? highscoreList.map((i, index) => {
                            return (
                                <div key={i.username}>
                                    <div key={i.username} className="top-players-card custom align-top d-flex " style={{ alignItems: "center" }}>
										<div className="col-1 col-lg-1 flex-column justify-content-center">
                                            <div className="text-wrap" style={{display: 'inline-block'}}>
                                                <div>
                                                    #{index + 1}
                                                </div>
											</div>
                                        </div>
										<div className="col-2 col-lg-2 position-relative">
											<img
												src={`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyuser?user=${i.username}`}
												className="rounded-circle position-absolute"
												id="top-players-games"
												alt="games avatar"
												key={index}
											/>
										</div>
                                        <div className="col-3 col-lg-5 flex-column justify-content-center text-wrap">
                                            <div className="text-wrap username" style={{display: 'inline-block'}}>
                                            <div id="top-players-info-bottom" className="text-wrap username">
                                                {i.display_name ? i.display_name : i.username}
                                            </div>
                                        </div>
                                    </div>
									<div className="col-4 col-lg-3 flex-column justify-content-center text-wrap">
                                        <div className="text-wrap" style={{display: 'inline-block'}}>
                                        	<div className="points">
                                                {activeHighscoreFilter}
                                            </div>
                                            <div className="numbers">
                                                {Number(i.score).toFixed(0)}
                                            </div>
                                        </div>
                                    </div>
									<div className="col-2 col-lg-2 d-inline-block top-players-trophy">
                                        <img
                                            src={index + 1 <= 3 ? (index + 1 === 1 ? gold : (index + 1 === 2 ? silver : bronze)) : medal}
                                            className="icon-32px"
                                            alt="player-icon"
                                        />
                                    </div>
                                </div>
                            </div>
							)
						}) : (
							<div className="edit-screen-text-area mt-2 mb-4">
								This highscore doesn't have a leaderboard yet
							</div>
						)}
						</>
					) : (
						<div className="edit-screen-text-area mt-2 mb-4">
							This game doesn't have any highscores
						</div>
					)}
					</>
				</div>
        	</div>
		)
	}

	const getRating = () =>
	{
		return (
			<>
				<div className="d-flex justify-content-between pt-3">
					<div className="custom-header mb-1 mt-2">
						<strong>Game Review</strong>
					</div>
					<div>
						<img
							src={helpButton}
							className="w-100 enable-click"
							style={{minWidth: '1rem', maxWidth: '2rem'}}
							alt="help button"
							data-tip
							data-for='rewards'
						/>
						<ReactTooltip id='rewards' type='light'>
							<div>Contribute to the community by rating games. To rate a game you must have played it before.</div>
							<div>A game rate will only appear after 5 or more rates have been given.</div>
						</ReactTooltip>
					</div>
				</div>
				{numberOfReviews > 5 ? 
					<div className="row rating w-100 col-12">
						<div className="rating-number">
							{Number(rating).toFixed(1)}
						</div>
						<div className="stars">
							<StarRating 
								rating={rating}
								starRatedColor="#fcd902"
								name="rating"
								numberOfStars={5}
								starDimension="30px"
								starSpacing="0px"
							/>
						</div>
						<div className="col-md-12 col-lg-3 col-12 row people mb-2">
							<div className="col-md-2 col-lg-1 col-1 img">
								<img
									src={avatar}
									className="icon-32px"
									alt="player-icon"
								/>
							</div>
							<div className="col-md-8 col-lg-3 col-3 ml-1">
								{numberOfReviews} total
							</div>
						</div>
					</div>
					:
					<div>
						Game rate will only appear after 5 or more rates have been given.
					</div>
				}
			</>
		)
	}

	const getPlayerReview = () =>
	{
		const hasPlayedGame = info && info.gamesDetails.find(e => e.name === game);

		if (hasPlayedGame)
		{
			return (
				<>
					<div className="custom-header mt-2">
						<strong>My Review</strong>
					</div>
					<div className="row rating w-100 col-12">
						<div className="rating-number">
							{playerReview}
						</div>
						<div className="stars">
							<StarRating 
								rating={playerReview}
								starRatedColor="#fcd902"
								name="playerReview"
								changeRating={value => prepareNewPlayerRating(value)}
								numberOfStars={5}
								starDimension="30px"
								starSpacing="0px"
								starHoverColor="blue"
							/>
						</div>
						<div className="w-100">
							<button className="editgamebtn custom-height"
								onClick={() => deleteReview()}>
								Delete Review
							</button>
						</div>
					</div>
				</>
			)
		}
		
		return (
			<div className="mb-2">
				You haven't played this game yet
			</div>
		)
	}

	const prepareNewPlayerRating = value =>
	{
		setConfirmRatingMessage(`You will be rating ${game} with ${value} stars`); 
		setNewPlayerReview(value);
		setConfirmRatingModal(true);
	}

	const rateGame = () =>
	{
		QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/review`, {
            game: game,
			rating: newPlayerReview
        }, {
			withCredentials: true
		})
		.then(response =>
		{
			let messageToShow = playerReview ? 
				`You updated the rate for ${game} with ${newPlayerReview} stars.  Thank you.` :
				`You have rated ${game} with ${newPlayerReview} stars. Thank you.`; 
				
			setModalMessage(messageToShow)
			setPlayerReview(newPlayerReview);
			setSuccessModal(true);
		})
		.catch(err =>
		{
			console.log(err);
			setFailureModal(true);
			setModalMessage(err.errorMessage)
		})
	}

	const deleteReview = () =>
	{
		QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/review/delete`, {
            game: game
        }, {
			withCredentials: true
		})
		.then(response =>
		{
			setPlayerReview(0);
			setSuccessModal(true);
			setModalMessage('Review removed with success')
		})
		.catch(err =>
		{
			setFailureModal(true);
			setModalMessage('Something went wrong')
		})
	}

	const getBugTesting = () =>
	{
		return gameBugSpotting.active ?
		(
			<div className="borders custom">
				<div className="col-12 d-flex justify-content-between pt-3">
					<div>
						<strong>Reward per Bug</strong>
					</div>
					<div>
						<img
							src={helpButton}
							className="w-100 enable-click"
							style={{minWidth: '1rem', maxWidth: '2rem'}}
							alt="help button"
							data-tip
							data-for='rewards'
						/>
						<ReactTooltip id='rewards' type='light'>
							<div>This is the range of values you can expect to receive if</div>
							<div>you submit a valid bug report that is accepted by the game developer.</div>
						</ReactTooltip>
					</div>
				</div>
				<div className="d-flex justify-content-between mt-5 col-12">
					<div>
						<div className="rewards type mb-2">Min Reward</div>
						<div className="rewards numbers">
							<span style={{fontSize: '3rem'}}>{gameBugSpotting.minReward}</span>
							<span className="yellow-qudo">QUDO</span>
						</div>
					</div>
					<div>
						<div className="rewards type mb-2">Max Reward</div>
						<div className="rewards numbers">
							<span style={{fontSize: '3rem'}}>{gameBugSpotting.maxReward}</span>
							<span className="yellow-qudo">QUDO</span>
						</div>
					</div>
				</div>
			</div>
		) :
		(
			<div
				className='edit-screen-text-area mt-2 text-center'
				//style={{fontSize: '3rem', color: '#b2b2b2'}}
			>
				This game does not have the bug spotting feature active
			</div>
		)
	}

	return(
		<>
			<Modal show={props.show} onHide={cleanOnHide}>
				<Modal.Body
					id="gamedetails-modal"
					style={{
						opacity: 1,
						justifyContent: 'initial',
						paddingTop: '3rem',
						paddingBottom: '1rem',
						paddingLeft: '2.5%',
						paddingRight: '2.5%'
					}}
				>
					<Modal.Header
						closeButton
						className='modal-header-position'
					/>

					<div className="row with-scroll col-12">
						<div className="modal-title-big m-0 col-12 mb-1">
							{displayName ? displayName : game}
						</div>
							<div className="col-12 mb-4">
								<div
									className="section-title enable-click"
									style={{lineHeight: 'normal'}}
									onClick={() => setShowGamedev(gamedev.username)}
								>
									{gamedev && (gamedev.display_name ? gamedev.display_name : gamedev.username)}

									{/*
									Array.isArray(genres) && genres.length > 0 &&
										<>
											{' | '}
											{genres.map((genre, index) => {
												var returnString = genre.name;

												if(index < genres.length - 1) {
													returnString = returnString + ', ';
												}

												return returnString;
											})}
										</>
									*/}
								</div>
							</div>

						{/* <div className={`col-12 col-md-4 ${windowSize && windowSize.width < 768 ? 'text-center' : ''}`}> */}
						<div className={`col-12 col-md-5 col-lg-4`}>
							<div style={{position: 'relative', display: 'inline-block'}}>
								<img 
									src={`${process.env.REACT_APP_QUDO_SERVER}/api/images/getbygame?gamename=${game}&rand=${Date.now()}`}
									onLoad={() => {
										setShowLabel(true);
									}}
									onError={(i) => {
										setShowLabel(true);
										(i.target.src = fallBack);
									}}
									className="details-modal-image img-fluid w-100"
									style={{maxHeight: '450px'}}
									alt="Game banner"
								/>

								{showLabel && label && (
									<img
										src={label}
										alt={altText}
										className="game-state-banner-modal"
										style={customStyle}
									/>
								)}

								<div className="mt-5">
									{comingSoon ? (
										<>
										<div className="section-title mt-3 mb-1">
											Estimated Release Date
										</div>
										<div className={`edit-screen-text-area`} style={{whiteSpace: 'initial'}}>
											{releaseDate}
										</div>
										</>
									) : (
										gameState === 'unavailable' ? (
											<>
											<div className="section-title mt-3 mb-1">
												Available at:
											</div>
											<div className={`edit-screen-text-area`} style={{whiteSpace: 'initial'}}>
												Marketplaces are not available at the moment
											</div>
											</>
										) : (
											<MarketPlaces marketplaces={marketplaces}/>
										)
									)}
								</div>
							</div>
							<div className="mt-3 w-100">
								{rating ? 
									getRating()
									:
									<div className="row col-12">
										This game doesn't have reviews
									</div>
								}
							</div>
							<div className="mt-3 w-100 margin-mobile">
								{info.type === process.env.REACT_APP_USER_TYPE && 
									getPlayerReview()
								}
							</div>
						</div>

						<div className="col-12 col-md-7 col-lg-8">
							{website && (
								<div className="mb-3">
									<div className={`custom-header`}>
										<strong>Official Website</strong>
									</div>
									<a
										style={{ color: "black" }}
										target="_blank"
										rel="noopener noreferrer"
										//href={`http://${website}`}
										href={website}
										className="edit-screen-text-area"
									>
										{website}
									</a>
								</div>
							)}
							{/* {windowSize && windowSize.width >= 768 ? (
								<>
								{/* <div className="modal-title-big m-0">
									{displayName ? displayName : game}
								</div> 

								<div className="section-title" style={{lineHeight: 'normal'}}>
									{gamedev && (gamedev.display_name ? gamedev.display_name : gamedev.username)}

									{/*Array.isArray(genres) && genres.length > 0 &&
										<>
											{' | '}
											{genres.map((genre, index) => {
												var returnString = genre.name;

												if(index < genres.length - 1) {
													returnString = returnString + ', ';
												}

												return returnString;
											})}
										</>
								</div>
								</>
							) : null} */}

							{Array.isArray(genres) && genres.length > 0 &&
								<div className="mb-3">
									<div className={`custom-header`}>
										<strong>{genres.length !== 1 ? 'Game Genres' : 'Game Genre'}</strong>
									</div>
									<div className="edit-screen-text-area">
										{genres.map((genre, index) => {
											var returnString = genre.name;

											if(index < genres.length - 1) {
												returnString = returnString + ', ';
											}

											return returnString;
										})}
									</div>
								</div>
							}

							{description && (
								<div className="mb-3">
									<div className="custom-header">
										<strong>Game description</strong>
									</div>
									<div id="game_description" className={`edit-screen-text-area`}>
										{description}
									</div>
								</div>
							)}
							{/*}
							<div id="game_description" className={`edit-screen-text-area`}>
								{showFullDescription ? description : String(description).slice(0, 250) + '...'}
							</div>
							{*/}

							{/*
							!showFullDescription && (
								<div className="text-center">
									<div
										className="mb-2 enable-click section-title"
										style={{display: 'inline-block'}}
										onClick={() => setShowFullDescription(true)}
									>
										Read more...
									</div>
								</div>
							)
							*/}

							<div id="RewardsSection" className="mt-4">
								<div className="section-title text-break">
									Rewards offered by this Game
								</div>
								{/* 
									We build an array of all the possible types. Then show the title.

									A few lines below we do the same thing. If the value we're accessing
									is the active tab, then we access the variable (using eval) to iterate through
									the values
								*/}
								<div className="row mt-2">
									{["achievements", "highscores", "products", "bug spotting"].map((a, index) => {
										return (
											<div
												key={a}
												onClick={() => setActiveTab(a)}
												style={{color: 'black', fontSize: '14px', textTransform: 'capitalize'}}
												//className={`enable-click section-subtitle mt-2 text-left col-6 col-sm-4 ${windowSize.width < 576 ? 'text-center' : ''} ${a === activeTab ? "highlight" : "faded"}`}
												className={`enable-click section-subtitle mt-2 text-left col-6 col-sm-3 text-center ${a === activeTab ? "highlight" : "faded"}`}
											>
												{a}
											</div>
										)
									})}
								</div>
								<div
									className="scroll-50-no-scrollbar mt-3 mb-5 mx-auto"
									//style={{maxWidth: '65%', overflowX: 'hidden'}}
								>
									{["achievements", "highscores", "products"].map((a) => {
										let icon = null;

										if(a === "products") {
											icon = product;
										} else if(a === "highscores") {
											icon = trophy;
										} else {
											icon = medal;
										}

										if(a === activeTab) {
											if(types[a] && Array.isArray(types[a]) && types[a].length > 0) {
												return (
													<div key={`${game + '-' + a}`}>
													{types[a].map((i) => {
														/* 
															So, just to make clear: if it is a dynamic achievement, then it has
															a truthy "isDynamic". Same if it is an highscore, it has a truthy "pot".
															In that case we want to show the coefficient in a percentage (x100).
															Otherwise we want to show the reward.

															If it is a product, then it doesn't have a reward, but has a price. So
															since JS evaluates from left to right, it should just show the price

															As for the unit, if it has a coefficient, then it's because it's a percentage
														*/ 
														const points = {
															value: i.isDynamic || i.pot ? String((i.coefficient * 100).toFixed(2)) : (i.reward || i.price),
															unit: i.coefficient ? "%" : "QUDO"
														}

														return (
															<div key={`${game + '-' + i.name}`} className="row achievementcard mx-auto col-12">
																<div className="col-2 text-center align-self-center">
																	<img src={icon} alt="medal" className="icon-32px"/> 
																</div>
																<div className="col-8 align-self-center">
																	<div className="achievementtitle">
																		{i.name}
																	</div>
																	<div className="achievementsubtitle">
																		{i.description}
																	</div>
																</div>
																<div className="col-2 align-self-center">
																	<div className="amountgreen text-right">
																		{points.value}
																	</div> 
																	<div className="amountgrey text-right">
																		{points.unit}
																	</div>
																</div>
															</div>
														);
													})}
													</div>
												);
											} else {
												var message = '';

												if(a === "achievements" || a === "highscores") {
													message = 'No ' + a.slice(0, -1).toLowerCase() + ' rewards offered at the moment by this game.';
												} else if(a === "products") {
													message = 'No ' + a.toLowerCase() + ' available at the moment for this game.';
												}

												return (
													<div key={message} className="edit-screen-text-area mt-2 text-center">
														{message}
													</div>
												);
											}
										}
									})}
									{(activeTab === 'bug spotting' && game) && (
										getBugTesting()
									)}
								</div>
							</div>

						</div>

						{
						(Array.isArray(mediaImages) && mediaImages.length > 0)
						|| (Array.isArray(mediaVideos) && mediaVideos.length > 0)
						?
						(
							<div className="col-12 mt-4 border-top">
								<div className="custom-header my-2">
									<strong>Gallery</strong>
								</div>
								<div
									className="mt-2"
									style={
										{
											maxWidth: windowSize.width < 768
											? '95%' : '75%',
											margin: 'auto'
										}
									}
								>
									<Carousel
										swipeable={true}
										useKeyboardArrows={true}
										showArrows={true}
										showThumbs={true}
										showIndicators={false}
										showStatus={false}
										dynamicHeight={true}
										infiniteLoop={true}
										renderItem={customRenderItem}
										renderThumbs={customRenderThumb}
									>
										{renderGalleryItems(mediaVideos, mediaImages)}
									</Carousel>
								</div>
							</div>
						) : (
							<div className="col-12" style={{height: '1.5rem'}}></div>
						)
						}

						<div className="col-12 border-top">
							{/*}
							<div className="custom-header mt-2">
								<strong>Leaderboards</strong>
							</div>
							{*/}
							<div className="row">
								{/*}
								<div className="col-12 col-md-6 scroll-40">
									{getTopPlayers()}
								</div>
								{*/}
								<div className="col-12 scroll-40">
									{getHighscores()}
								</div>
							</div>
						</div>				
					</div>
				</Modal.Body>
				<MessageModal
					show={successModal} 
					message={modalMessage}
					hide={() => setSuccessModal(!successModal)}
				/>
				<MessageModal
					show={failureModal} 
					message={modalMessage}
					hide={() => setFailureModal(!failureModal)}
				/>
				<ConfirmModal
					show={confirmRatingModal}
					hide={() => setConfirmRatingModal(false)}
					message={confirmRatingMessage}
					confirm={() =>{rateGame(); setConfirmRatingModal(false)}}
				/>
			</Modal>

			{showGamedev && (
				<GamedevDetailsModal
					show={showGamedev}
					setShow={setShowGamedev}
					gamedev={showGamedev}
				/>
			)}
		</>
	);
}
