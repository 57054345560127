import React, { useState, useEffect, useRef } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import useDebounce from '../../../helpers/useDebounce';
import QUDOServer from '../../../helpers/QUDOServerConnection';

import regexes from "../../../helpers/validationRegexes";

export function NameInput(props) {
    const [value, setValue] = useState(props.initial ? props.initial : '');
    const [virgin, setVirgin] = useState(true);

    const change = (event) => {
        if(props.ignoreValidation) {
            setValue(event.target.value);
            setVirgin(false);
        } else {
            if(event.currentTarget.checkValidity()) {
                setValue(event.target.value);
                setVirgin(false);
            } else {
                setValue(null);
            }
        }
    }

    useEffect(() => {
        props.set(value)
    });

    return(
        <InputGroup>
            <Form.Group 
                controlId="validationCustomTextArea" 
                style={props.className ? {width: "100%", margin: '0px'} : {width: "100%"}}
            >
                <Form.Label className="edit-input-title">
                    {props.title}
                </Form.Label>
                <Form.Control 
                    className={props.className ? props.className : "input-field-custom"}
                    type="text"
                    name="Name"
                    minLength={props.ignoreValidation ? '0' : '4'}
                    maxLength={props.ignoreValidation ? '35' : '20'}
                    disabled={props.disabled}
                    pattern={props.pattern}
                    required
                    style={{ height: "4.5rem" }}
                    value={value}
                    onChange={change}
                    isInvalid={props.ignoreValidation ? false : (!virgin && !value)}
                    placeholder={props.placeholder} />
                <Form.Control.Feedback type="invalid">
                    Please choose a valid name
                </Form.Control.Feedback>
                {props.invalid &&
                <div className="invalid-feedback" style={{display: 'initial'}}>
                    {props.errorMessage}
                </div>}
            </Form.Group>
        </InputGroup>
    );
}

// export function AliasInput(props) {
//     const [value, setValue] = useState(props.initial ? props.initial : false);
//     const [virgin, setVirgin] = useState(true);

//     const change = (event) => {
//         if(event.currentTarget.checkValidity()) {
//             setValue(event.target.value)
//             setVirgin(false)
//         } else {
//             setValue(null)
//         } 
//     }

//     useEffect(() => {
//         props.set(value)
//     })

//     return(
//         <InputGroup>
//             <Form.Label className="edit-input-title"  >
//                 {props.title}
//             </Form.Label>
//             <Form.Control
//                 className={`edit-field col-12`}
//                 style={{ height: "4.5rem" }}
//                 type="text"
//                 name="Alias"
//                 minLength="4"
//                 maxLength="20"
//                 disabled={props.disabled}
//                 pattern={regexes.achievementAlias}
//                 required
//                 onChange={change}
//                 isInvalid={!virgin && !value}
//                 placeholder={props.placeholder} />
//             <Form.Control.Feedback type="invalid">
//                 Please choose a valid alias
//             </Form.Control.Feedback>
//         </InputGroup>
//     )
// }

export function TextAreaInput(props) {
    const [value, setValue] = useState(props.initial ? props.initial : '');
    const [virgin, setVirgin] = useState(true);

    const change = (event) => {
        if(event.currentTarget.checkValidity()) {
            setValue(event.target.value)
            setVirgin(false)
        } else {
            setValue('')
        } 
    }

    useEffect(() => {
        props.set(value)
    });

    return(
        <InputGroup>
            <Form.Group 
                controlId="validationCustomTextArea" 
                style={props.className ? {width: "100%", margin: '0px'} : {width: "100%"}}
            >
                <Form.Label className="edit-input-title">
                    {props.title}
                </Form.Label>
                <Form.Control
                    className={props.className ? props.className : "col-12 input-field-custom-tall text-area-input"}
                    as="textarea"
                    required={props.required}
                    name="Description"
                    style={props.style ? props.style : {height: "4.5rem"}}
                    minLength={props.minLength ? props.minLength : '0'}
                    maxLength={props.maxLength ? props.maxLength : "250"}
                    onChange={change}
                    value={value}
                    disabled={props.disabled}
                    isInvalid={props.required ? (!virgin && !value) : false}
                    placeholder={props.placeholder} />
                <Form.Control.Feedback type="invalid">
                    Please choose a valid description
                </Form.Control.Feedback>
            </Form.Group>
        </InputGroup>
    );
}

export function DisplayNameInput(props) {
    const [value, setValue] = useState(props.initial ? props.initial : '');
    const [initialValue, setInitialValue] = useState(props.initial ? props.initial : '');
    const [inputValue, setInputValue] = useState(props.initial ? props.initial : '');
    const debouncedInputValue = useDebounce(inputValue, 250);

    const [valid, setValid] = useState();
    const [invalidMessage, setInvalidMessage] = useState("");
    const [virgin, setVirgin] = useState(true);
    const form = useRef();

    function isValidDisplayName(displayName, userType) 
    {
        if(!displayName || (initialValue && displayName === initialValue)) {
            setValid(true);
            return;
        }

        if(props.type === 'service') {
            setValid(true);
        } else if(props.type === 'game') {
            QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/game/checkDisplayName?display_name=${displayName}`)
            .then((data) => {
                if(data.data) {
                    setValid(true);
                } else {
                    setInvalidMessage("Display name is unavailable");
                    setValid(false);
                }
            })
            .catch((err) => {
                setValid(true);
            });
        } else {
            QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/profile/checkDisplayName?display_name=${displayName}&type=${userType}`)
            .then((data) => {
                if(data.data) {
                    setValid(true);
                } else {
                    setInvalidMessage("Display name is unavailable");
                    setValid(false);
                }
            })
            .catch((error) => {
                setValid(true);
            });
        }
    }

    //This function is used to force an update to the username, therefore checking its validity
    /*
    async function reCheckDisplayName() {
        const valueBackup = value;

        await setValue('');
        await setValue(valueBackup);
    }

    useEffect(() => {
        reCheckDisplayName();
    }, [props.checkDisplayNameValidity]);
    */

    useEffect(() => {
        if(valid) {
            props.set(value);
            
            if(props.setValid) {
                props.setValid(true);
            }
        } else {
            props.set(null);
            
            if(props.setValid) {
                props.setValid(false);
            }
        }
    }, [value, valid]);

    useEffect(() => {
        if(value && value !== initialValue) {
            setVirgin(false);
        }

        if(form.current.checkValidity()) {
            setValid(true);
            isValidDisplayName(value, props.userType);
        } else {
            setInvalidMessage("Invalid display name");
            setValid(false);
        } 
    }, [value, props.checkDisplayNameValidity]);

    useEffect(() => {
        setValue(debouncedInputValue);
    }, [debouncedInputValue]);

    return(
        <InputGroup>
            <Form.Group
                style={props.className ? {width: "100%", margin: '0px'} : {width: "100%"}}
            >
                {props.title && (
                    <Form.Label className="edit-input-title">
                        {props.title}
                    </Form.Label>
                )}
				<Form.Control
                    isValid={props.showValid && valid && !virgin}
                    isInvalid={!valid && !virgin}
                    ref={form}
                    className={props.className ? props.className : "input-field-custom"}
                    style={props.style ? props.style : {}}
					type="text"
					name="username"
					placeholder={props.placeholder ? props.placeholder : 'Display Name'}
					aria-describedby="inputGroupPrepend"
                    minLength={props.minLength ? props.minLength : "2"}
                    maxLength={props.maxLength ? props.maxLength : "25"}
					//maxLength={props.userType === process.env.REACT_APP_USER_TYPE ? '50' : '100'}
                    onChange={(e) => setInputValue(e.target.value)}
                    value={inputValue}
                    disabled={props.disabled}
				/>
				<Form.Control.Feedback type="invalid">
                    {invalidMessage}
                </Form.Control.Feedback>
			</Form.Group>
        </InputGroup>
    );
}

export function GameNameInput(props) 
{
    const [value, setValue] = useState(props.initial ? props.initial : '');
    const [initialValue, setInitialValue] = useState(props.initial ? props.initial : '');
    const [inputValue, setInputValue] = useState(props.initial ? props.initial : '');
    const debouncedInputValue = useDebounce(inputValue, 250);

    const [valid, setValid] = useState(false);
    const [invalidMessage, setInvalidMessage] = useState("");
    const [virgin, setVirgin] = useState(true);
    const form = useRef();

    function isValidDisplayName(displayName) 
    {
        if(displayName) 
        {
			QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/game/exists?name=${displayName}`)
			.then(data => 
            {
				if(data.data.exists) 
                {
					setInvalidMessage('A game with this name already exists!');
					setValid(false);
				} else 
                {
					setValid(true);
				}
			});
		}
        
    }

    useEffect(() => 
    {
        if(valid) 
        {
            props.set(value);
            
            if(props.setValid) {
                props.setValid(true);
            }
        } else 
        {
            props.set(null);
            
            if(props.setValid) {
                props.setValid(false);
            }
        }
    }, [value, valid]);

    useEffect(() => {
        if(value && value !== initialValue) 
        {
            setVirgin(false);
        }

        if(form.current.checkValidity()) 
        {
            isValidDisplayName(value);
        } else 
        {
            setInvalidMessage("Invalid game name");
            setValid(false);
        } 
    }, [value]);

    useEffect(() => 
    {
        setValue(debouncedInputValue);
    }, [debouncedInputValue]);

    return(
        <InputGroup>
            <Form.Group
                style={props.className ? {width: "100%", margin: '0px'} : {width: "100%"}}
            >
                {props.title && (
                    <Form.Label className="edit-input-title">
                        {props.title}
                    </Form.Label>
                )}
				<Form.Control
                    isValid={valid && !virgin}
                    isInvalid={!valid && !virgin}
                    ref={form}
                    className={props.className ? props.className : "input-field-custom"}
                    style={props.style ? props.style : {}}
					type="text"
					name="username"
					placeholder={props.placeholder ? props.placeholder : 'Game Name'}
					aria-describedby="inputGroupPrepend"
                    minLength={props.minLength ? props.minLength : "2"}
                    maxLength={props.maxLength ? props.maxLength : "25"}
                    onChange={(e) => setInputValue(e.target.value)}
                    value={inputValue}
                    pattern={props.pattern}
				/>
				<Form.Control.Feedback type="invalid">
                    {invalidMessage}
                </Form.Control.Feedback>
			</Form.Group>
        </InputGroup>
    );
}

export function UsernameInput(props) {
    const form = useRef();

    const [value, setValue] = useState(props.value ? props.value : '');
    const [inputValue, setInputValue] = useState(props.value ? props.value : '');
    const debouncedInputValue = useDebounce(inputValue, 250);

    const [valid, setValid] = useState();
    const [virgin, setVirgin] = useState(true);
    const [invalidMessage, setInvalidMessage] = useState("");

    function isValidUsername(username) {
        //console.log(username);

        if(props.unique) {
            QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/gettype?username=${username}`)
            .then((data) => {
                setInvalidMessage("Username is already taken");
                setValid(false);
            })
            .catch((error) => {
                setValid(true);
            });
        } else {
            setValid(true);
        }
    }

    // So that the form reminds that this field needs filling
    useEffect(() => {
        if(!props.forceCheck) return;
        if(inputValue !== '')
            return;
        setValid(false);
        setVirgin(false);
        //form.current.reportValidity(); // This is a pop-up
    }, [props.forceCheck]);

    //This function is used to force an update to the username, therefore checking its validity
    /*
    async function reCheckUsername() {
        const valueBackup = value;

        await setValue('');
        await setValue(valueBackup);
    }

    useEffect(() => {
        reCheckUsername();
    }, [props.checkUsernameValidity]);
    */

    useEffect(() => {
        if(valid) {
            props.set(value);
        } else {
            props.set(null);
        }
    }, [value, valid]);

    useEffect(() => {
        if(value) {
            setVirgin(false)
        }

        if(form.current.checkValidity() || props.ignoreValidation) {
            setValid(true)
            isValidUsername(value)
        } else {
            setInvalidMessage("Invalid username")
            setValid(false)
        } 
    }, [value, props.checkUsernameValidity]);

    useEffect(() => {
        setValue(debouncedInputValue);
    }, [debouncedInputValue]);

    return(
        <InputGroup>
            <Form.Group 
                controlId="validationCustomUsername" 
                style={props.className ? {width: "100%", margin: '0px'} : {width: "100%"}}
            >
					<Form.Control
                        ref={form}
                        required
						className="input-field-custom"
						type="text"
						name="username"
                        placeholder={props.placeholder ? props.placeholder : 'Username'}
						aria-describedby="inputGroupPrepend"
						minLength={props.minLength ? props.minLength : 4}
						maxLength={props.maxLength ? props.maxLength : 20}
                        pattern={regexes.username}
                        isInvalid={!valid && !virgin}
                        value={inputValue}
                        onChange={(e) => {
                            //setInputValue(e.target.value);
                            setInputValue(String(e.target.value).trim().toLowerCase());
                            if(props.setRandomizeAccountName){
                                props.setRandomizeAccountName(true);
                            }
                        }}
                        onBlur={props.onBlur ? props.onBlur : null}
					/>
					<Form.Control.Feedback type="invalid">
                        {invalidMessage}
                    </Form.Control.Feedback>
			</Form.Group>
        </InputGroup>
    );
}


/* 
    Checks if the username has email confirmed and valid blockchain name.
*/
export function ReferralInputt(props) {
    const form = useRef();

    const [value, setValue] = useState(props.value ? props.value : '');
    const [inputValue, setInputValue] = useState(props.value ? props.value : '');
    const debouncedInputValue = useDebounce(inputValue, 250);

    const [valid, setValid] = useState();
    const [virgin, setVirgin] = useState(true);
    const [invalidMessage, setInvalidMessage] = useState("");

    function isValidReferral(username) {
        const url = "/accounts/valid_referral";
        
        console.log("Checking referral ...")
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}${url}`,
        {username: username})
        .then((data) => {
            setValid(true);
        })
        .catch((error) => {
            console.log(error);
            setInvalidMessage(error.errorMessage);
            setValid(false);
        });
    }

    // So that the form reminds that this field needs filling
    useEffect(() => {
        if(!props.forceCheck) return;
        if(inputValue !== '')
            return;
        setValid(false);
        setVirgin(false);
        //form.current.reportValidity(); // This is a pop-up
    }, [props.forceCheck]);

    useEffect(() => {
        if(valid) {
            props.set(value);
        } else {
            props.set(null);
        }
    }, [value, valid]);

    useEffect(() => {
        if(value) {
            setVirgin(false)
        }

        if(form.current.checkValidity() || props.ignoreValidation) {
            setValid(true)
            isValidReferral(value)
        } else {
            setInvalidMessage("Invalid username")
            setValid(false)
        } 
    }, [value, props.checkUsernameValidity]);

    useEffect(() => {
        setValue(debouncedInputValue);
    }, [debouncedInputValue]);

    return(
        <InputGroup>
            <Form.Group 
                controlId="validationCustomUsername" 
                style={props.className ? {width: "100%", margin: '0px'} : {width: "100%"}}
            >
					<Form.Control
                        ref={form}
                        required
						className="input-field-custom"
						type="text"
						name="username"
                        placeholder={props.placeholder ? props.placeholder : 'Username'}
						aria-describedby="inputGroupPrepend"
						minLength={props.minLength ? props.minLength : 4}
						maxLength={props.maxLength ? props.maxLength : 20}
                        pattern={regexes.username}
                        isInvalid={!valid && !virgin}
                        value={inputValue}
                        onChange={(e) => {
                            setInputValue(String(e.target.value).toLowerCase());
                        }}
                        onBlur={props.onBlur ? props.onBlur : null}
					/>
					<Form.Control.Feedback type="invalid">
                        {invalidMessage}
                    </Form.Control.Feedback>
			</Form.Group>
        </InputGroup>
    );
}

/*
    By default this component shows a error message if the account name doesn't exist in the blockchain.
    If the prop 'newAccount' is passed, it shows a error message if the account name already exists in the blockchain.
*/
export function AccNameInput(props) {
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const debouncedInputValue = useDebounce(inputValue, 250);

    const [valid, setValid] = useState();
    const [virgin, setVirgin] = useState(true);
    const [error, setError] = useState('');
    const form = useRef();

    //This function is used to force an update to the accountname, therefore checking its validity
    /*
    async function reCheckAccountname() {
        const valueBackup = value;

        await setValue(null);
        await setValue(valueBackup);
    }

    useEffect(() => {
        reCheckAccountname();
    }, [props.checkAccountnameValidity]);
    */

    function isValidAccName(accname) {
		QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/isvalidaccname?accname=${accname}`)
        .then((data) => {
            const accountExists = data.data;

            if(accountExists) {
                if(props.newAccount) {
                    setError('Account Name Already Exists');
                    setValid(false);
                } else {
                    setValid(true);
                }
            } else {
                if(props.newAccount) {
                    setValid(true);
                } else {
                    setError("This Account Doesn't Exist");
                    setValid(false);
                }
            }
        })
        .catch((error) => {
            //setError('TELOS account name must be 12 characters long, and can contain lowercase (a-z) and certain digits (1–5)');
            setError(error.errorMessage);
            setValid(false);
        });
    }

	function randomizeAccountName() {
		const url = process.env.REACT_APP_QUDO_SERVER + '/api/randomaccname';
        
        QUDOServer.get(url, {
            params: {username: props.username},
            headers: {"Content-Type": "application/json"},
            json: true,
            withCredentials: true
        })
        .then(data => {
            setInputValue(data.data.username);
        })
        .catch(error => {
            console.log(error);
        });
    }

    useEffect(() => {
        if(props.randomizeAccountName && process.env.REACT_APP_TELOS_ENVIRONMENT !== 'testnet') {
            randomizeAccountName();
        }
    }, [props.randomizeAccountName]);

    useEffect(() => {
        if(valid) {
            props.set(value);

            if(props.setValid) {
                props.setValid(true);
            }
        } else {
            props.set(null);

            if(props.setValid) {
                props.setValid(false);
            }
        }
    }, [value, valid]);

    useEffect(() => {
        if(value) {
            setVirgin(false);
        }

        // if(form.current.checkValidity()) {
        //     setValid(true);
        //     isValidAccName(value);
        // } else {
        //     setError('TELOS account name must be 12 characters long, and can contain lowercase (a-z) and certain digits (1–5)');
        //     setValid(false);
        // }

        // setValid(false);
        isValidAccName(value);
    }, [value, props.checkAccountnameValidity]);

    useEffect(() => {
        setValue(debouncedInputValue);
    }, [debouncedInputValue]);

    return(
        <InputGroup>
            <Form.Group 
                controlId="validationCustomUsername" 
                style={props.className ? {width: "100%", margin: '0px'} : {width: "100%"}}
            >
				<Form.Control
                    required
                    isInvalid={!valid && !virgin}
                    ref={form}
					className="input-field-custom"
					type="text"
					name="accountName"
					placeholder="TELOS account name"
					aria-describedby="inputGroupPrepend"
					//minLength="12"
					//maxLength="12"
                    //pattern={regexes.account_names}
                    onChange={(e) => setInputValue(e.target.value)}
                    value={inputValue}
				/>
				<Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
			</Form.Group>
        </InputGroup>
    );
}

export function EmailInput(props) {
    const form = useRef();

    const [initialEmail, setInitialEmail] = useState(props.initial);

    const placeholder = props.placeholder ? props.placeholder : "Valid Email for verification";

    const [valid, setValid] = useState();
    const [virgin, setVirgin] = useState(true);
    const [value, setValue] = useState(props.value ? props.value : '');
    const [inputValue, setInputValue] = useState(props.value ? props.value : '');
    const debouncedInputValue = useDebounce(inputValue, 250);
    useEffect(() => {
        setValue(debouncedInputValue);
    }, [debouncedInputValue]);

    const [errorMessage, setErrorMessage] = useState('');

    function validateEmail(email, userType) {
        if(initialEmail && initialEmail === email) {
            setValid(true);
        } else {
            QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/profile/validateEmail?email=${email}&type=${userType}`)
            .then((data) => {
                if(data.data) {
                    setValid(true);
                } else {
                    //setErrorMessage("This email address is already being used by an account of this type");
                    setErrorMessage("This email address is already being used by another account");
                    setValid(false);
                }
            })
            .catch((err) => {
                setValid(true);
            });
        }
    }

    useEffect(() => {
        if(valid) {
            props.set(value);
        } else {
            props.set(null);
        }
    });

    useEffect(() => {
        if(!props.forceCheck) return;
        if(inputValue !== '')
            return;
        setValid(false);
        setVirgin(false);
        //form.current.reportValidity(); // This is a pop-up
    }, [props.forceCheck]);

    useEffect(() => {
        if(value) {
            setVirgin(false);
        }

        if(form.current.checkValidity()) {
            setValid(true);

            if(props.validate && props.userType) {
                validateEmail(value, props.userType);
            }
        } else {
            setErrorMessage('Invalid email address');
            setValid(false);
        }
    }, [value, props.userType]);

    return(
        <InputGroup>
            <Form.Group 
                controlId="validationCustomUsername" 
                style={props.className ? {width: "100%", margin: '0px'} : {width: "100%"}}
            >
                <Form.Label className="edit-input-title"  >
                    {props.title}
                </Form.Label>
				<Form.Control
                    ref={form}
                    required
                    className={props.className ? props.className : "input-field-custom"}
                    style={props.style ? props.style : {}}
					type="text"
					name="email"
					placeholder={placeholder}
					aria-describedby="inputGroupPrepend"
                    minLength="4"
                    pattern={regexes.email}
                    isInvalid={!valid && !virgin}
                    value={inputValue}
                    onChange={(e) => {
                        //setInputValue(e.target.value);
                        setInputValue(String(e.target.value).toLowerCase());
                    }}
				/>
				<Form.Control.Feedback type="invalid">
                    {errorMessage}
                </Form.Control.Feedback>
			</Form.Group>
        </InputGroup>
    );
}

export function WebsiteInput(props) {
    const form = useRef();

    const [valid, setValid] = useState();
    const [virgin, setVirgin] = useState(true);
    const [value, setValue] = useState(props.initial ? props.initial : "");

    useEffect(() => {
        if(valid || props.ignoreValidation) {
            props.set(value);
        } else {
            props.set(null);
        }
    });

    useEffect(() => {
        if(value) {
            setVirgin(false);
        }

        if(form.current.checkValidity() || props.ignoreValidation) {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [value]);

    return(
        <InputGroup>
            <Form.Group 
                controlId="validationCustomWebsite" 
                style={props.className ? {width: "100%", margin: '0px'} : {width: "100%"}}
            >
					<Form.Control
                        isInvalid={!valid && !virgin}
                        ref={form}
						className={props.className ? props.className : "input-field-custom"}
                        type="text"
                        value={value}
                        name={props.name}
                        disabled={props.disabled}
                        pattern={props.ignoreValidation ? null : regexes.website}
						placeholder={props.placeholder ? props.placeholder : "Website"}
						aria-describedby="inputGroupPrepend"
						minLength="4"
                        required
                        onChange={(e) => setValue(e.target.value)}
					/>
					<Form.Control.Feedback type="invalid">Website Invalid</Form.Control.Feedback>
			</Form.Group>
        </InputGroup>
    );
}

export function PasswordInput(props) {
    const form = useRef();

    const [value, setValue] = useState(props.value ? props.value : '');
    const [inputValue, setInputValue] = useState(props.value ? props.value : '');
    const debouncedInputValue = useDebounce(inputValue, 250);
    useEffect(() => {
        setValue(debouncedInputValue);
    }, [debouncedInputValue]);
    const [valid, setValid] = useState();
    const [virgin, setVirgin] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);


    useEffect(() => {
        if(valid) {
            props.set(value);
        } else {
            props.set(null);
        }
    });


    // So that the form reminds that this field needs filling
    useEffect(() => {
        if(!props.forceCheck) return;
        if(inputValue !== '')
            return;
        setValid(false);
        setVirgin(false);
        //form.current.reportValidity(); // This is a pop-up
    }, [props.forceCheck]);


    useEffect(() => {
        if(value) {
            setVirgin(false);
        }

        if(form.current.checkValidity()) {
            setValid(true);
        } else {
            setValid(false);
            setErrorMessage(
                'Password must have between 6 and 64 characters, uppercase and lowercase letters, one number and no whitespaces'
            );
        }
    }, [value]);


    return (
        <InputGroup>
            <Form.Group
                controlId="passwordControl"
                style={props.className ? {width: "100%", margin: '0px'} : {width: "100%"}}
            >
                <Form.Control
                    ref={form}
                    required
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder={props.placeholder ? props.placeholder : "Password"}
                    maxLength={64}
                    pattern={props.validate ? regexes.password : null}
                    isInvalid={(!valid && !virgin) || props.forceInvalid}
                    className={props.className ? props.className : "input-field-custom"}
                    style={props.style ? props.style : {}}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                />

                <div
                    onClick={() => setShowPassword(!showPassword)}
                    className="d-inline-block enable-click"
                    style={{
                        position: 'absolute',
                        top: '20px',
                        right: '10px',
                        fontSize: '20px',
                        backgroundColor: 'white',
                        zIndex: '2'
                    }}
                >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye}/>
                </div>

                <Form.Control.Feedback type="invalid">
                    {props.forceInvalid ? props.forceInvalid : (!valid && props.validate ? errorMessage : null)}
                </Form.Control.Feedback>
            </Form.Group>
        </InputGroup>
    );
}


/* 
    Checks if the username has email confirmed and valid blockchain name.
*/
export function ReferralInput(props) {
    /* props.forceInvalid (+set)
    // props.setForceInvalid
    // props.value
    // props.set
    // props.forceCheck
    
    Example:
        placeholder="Confirm password"
        value={referralUsername}
        set={setReferralUsername} 
        forceInvalid={referraMsg}
        setForceInvalid={setReferralMsg}
        forceCheck={forceCheck}
    */

        const form = useRef();

    const [value, setValue] = useState(props.value ? props.value : '');
    const [inputValue, setInputValue] = useState(props.value ? props.value : '');
    const debouncedInputValue = useDebounce(inputValue, 250);
    useEffect(() => {
        setValue(debouncedInputValue);
    }, [debouncedInputValue]);
    const [valid, setValid] = useState();
    const [virgin, setVirgin] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');


    useEffect(() => {
        if(valid) {
            props.set(value);
        } else {
            props.set(null);
        }
    });


    // So that the form reminds that this field needs filling
    useEffect(() => {
        if(!props.forceCheck) return;
        if(inputValue !== '')
            return;
        setValid(false);
        setVirgin(false);
        //form.current.reportValidity(); // This is a pop-up
    }, [props.forceCheck]);

    function verifyReferral() {
        if(value !== ''){
			const url = process.env.REACT_APP_QUDO_SERVER + '/accounts/valid_referral';
            console.log("Calling server:", url);
			QUDOServer.post(url, {referralUsername: value})
			.then(data => {
				console.log("Referral data", data);
                props.setForceInvalid("");
                props.set(value);
                setVirgin(true);
                setValid(true);
			})
			.catch(error => {
				console.log(error);
				props.setForceInvalid(error.errorMessage);
			});
		}else{
            console.log("Referral field cleared");
            if(props.setForceInvalid)
                props.setForceInvalid("");
        }
    }

    useEffect(() => {
        if(value) {
            setVirgin(false);
        }

        if(value === ""){
            setValid(true);
            props.setForceInvalid('');
        }else if(form.current.checkValidity()) {
            setValid(true);
            verifyReferral();
        } else {
            setValid(false);
            setErrorMessage(
                'Invalid referral username'
            );
            props.setForceInvalid('Invalid referral username');
        }
    }, [value]);

    // So that the form reminds that this field needs filling
    useEffect(() => {
        if(!props.forceCheck) return;
        if(inputValue !== '')
            return;
        setVirgin(false);
        //form.current.reportValidity(); // This is a pop-up
    }, [props.forceCheck]);


    return (
        <InputGroup>
            <Form.Group
                controlId="referralControl"
                style={props.className ? {width: "100%", margin: '0px'} : {width: "100%"}}
            >
                <Form.Control
                    ref={form}
                    required
                    type={'text'}
                    name="referral"
                    placeholder={props.placeholder ? props.placeholder : "Referral username (optional)"}
                    minLength={props.minLength ? props.minLength : 4}
                    maxLength={props.maxLength ? props.maxLength : 20}
                    pattern={regexes.username}
                    isInvalid={(!valid && !virgin) || props.forceInvalid}
                    onBlur={props.onBlur ? props.onBlur : null}
                    className={props.className ? props.className : "input-field-custom"}
                    style={props.style ? props.style : {}}
                    value={inputValue}
                    onChange={(e) => setInputValue(String(e.target.value).toLowerCase())}
                />
                <Form.Control.Feedback type="invalid">
                {props.forceInvalid ? props.forceInvalid : (!valid && props.validate ? errorMessage : null)}
                </Form.Control.Feedback>
            </Form.Group>
        </InputGroup>
    );
}