import React, { useState, useEffect } from "react";
import {Modal} from "react-bootstrap";
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import {WebsiteInput} from "../forms/text";
import {ButtonInput} from "../forms/buttons";
import {ImageUploadModal} from "./ImageUploadModal";
import {YoutubeSlide, customRenderItem, customRenderThumb} from '../react-responsive-carousel/YoutubeSlide';

import useWindowSize from "../../../helpers/useWindowSize";
import QUDOServer from "../../../helpers/QUDOServerConnection";

const fallbackAvatar = require("../../../images/fallbackAvatar.png");

export function GameGalleryModal(props) {
    const windowSize = useWindowSize();

    const [videos, setVideos] = useState([]);
    const [newVideo, setNewVideo] = useState('');

    const [images, setImages] = useState([]);
    const [imageUpload, setImageUpload] = useState(false);

    useEffect(() => {
        updateMedia();
    }, []);

    function updateMedia() {
        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/gameinfo?gamename=${props.game}`)
        .then(async (response) => {
            //console.log(response.data);
            setVideos(response.data.media_videos);
            setImages(response.data.media_images);
        });
    }

    function deleteImage(imageID) {
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/media/image/delete`, {
            gamename: props.game,
            imageID: imageID
        }, {
            withCredentials: true
        })
        .then(response => {
            updateMedia();
        })
        .catch(error => {
            //window.alert('An error occurred!');
            window.alert(error.errorMessage);
        });
    }

    function saveImage(e) {
        const formData = new FormData();

        formData.append('image', e);
        formData.append('gamename', props.game);

        const config = {
            withCredentials: true
        }

        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/media/image/replace`, formData, config)
        .then(data => {
            updateMedia();
        });
    }

    function addVideo() {
        const videosCopy = videos;

        const newVideoObj = {
            videoLink: newVideo,
            date: Date.now()
        }

        videosCopy.push(newVideoObj);
        setVideos(videosCopy);

        setNewVideo('');

        saveVideos();
    }

    function deleteVideo(index) {
        var videosCopy = videos;
        videosCopy.splice(index, 1);
        setVideos(videosCopy);
        saveVideos();
    }

    function saveVideos() {
        const body = {
            gamename: props.game,
            videos: videos
        }

        const config = {
            withCredentials: true
        }

        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/media/videos/replace`, body, config)
        .then(data => {
            updateMedia();
        });
    }

	return(
        <>
		<Modal show={props.show} onHide={props.hide}>
			<Modal.Body
				id="gamedetails-modal"
				style={{
                    opacity: 1,
                    justifyContent: 'initial'
                }}
			>
				<Modal.Header
					closeButton
					className='modal-header-position'
				/>

                <div className="row with-scroll">
                    <div className="col-12 mt-5 mb-2 text-center">
                        <h3>Video Gallery</h3>
                    </div>

                    <div className="col-12">
                        {Array.isArray(videos) && videos.length > 0 ? (
                            <div 
								style={
									windowSize.width < 768
									? {maxWidth: '95%', margin: 'auto'}
									: {maxWidth: '75%', margin: 'auto'}
								}
                            >
								<Carousel
									swipeable={true}
									useKeyboardArrows={true}
									showArrows={true}
									showThumbs={true}
									showIndicators={false}
									showStatus={false}
									dynamicHeight={true}
									infiniteLoop={true}
									renderItem={customRenderItem}
									renderThumbs={customRenderThumb}
                                    onClickThumb={(index) => {
                                        /*
                                        if(props.isEditing) {
                                            deleteVideo(index);
                                        }
                                        */
                                    }}
								>
                                    {videos.map((video, index) => {
										return(
                                            <YoutubeSlide
                                                key={video.videoLink}
                                                url={video.videoLink}
                                                delete={props.isEditing ? (
                                                    () => {
                                                        if(props.isEditing) {
                                                            deleteVideo(index);
                                                        }
                                                    }
                                                ) : (
                                                    null
                                                )}
                                            />
										);
									})}
                                </Carousel>
                            </div>
                        ) : (
                            <div className="text-center">
                                There are no gallery videos for this game!
                            </div>
                        )}
                    </div>

                    {props.isEditing && Array.isArray(videos) && videos.length < 3 && (
                        <div className="col-12 mb-4">
                            <div className="row mx-auto" style={{maxWidth: '650px'}}>
                                <div className="col-12">
									<WebsiteInput
									    name="URL"
										placeholder="New Youtube Link"
                                        disabled={!props.isEditing}
                                        ignoreValidation={true}
										initial={''}
										value={newVideo}
                                        set={setNewVideo}
                                        className="input-field-custom mx-0"
									/>
                                </div>
                                <div className="col-12">
									<ButtonInput
										trigger
										label="Add"
										disabled={!props.isEditing || !newVideo}
										set={addVideo}
                                        className="editgamebtn w-100 mt-0"
                                        style={
                                            !props.isEditing || !newVideo
                                            ? {backgroundColor: '#ededed'}
                                            : {backgroundColor: '#fbd731'}
                                        }
									/>
                                </div>
                                <div className="col-12 section-subtitle text-center my-0">
                                    * Only 3 videos per game. If the thumbnail doesn't show,
                                    try to change the format of the YouTube link.
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="col-12 mt-4 mb-2 text-center">
                        <h3>Image Gallery</h3>
                    </div>

                    <div className="col-12">
                        {Array.isArray(images) && images.length > 0 ? (
                            <div 
								style={
									windowSize.width < 768
									? {maxWidth: '95%', margin: 'auto'}
									: {maxWidth: '75%', margin: 'auto'}
								}
                            >
                                <Carousel
									swipeable={true}
									useKeyboardArrows={true}
                                    showArrows={true}
                                    showThumbs={true}
                                    showIndicators={false}
                                    showStatus={false}
                                    dynamicHeight={true}
                                    infiniteLoop={true}
									renderItem={customRenderItem}
									renderThumbs={customRenderThumb}
                                    onClickThumb={(index) => {
                                        /*
                                        if(props.isEditing) {
                                            deleteImage(images[index].imageID);
                                        }
                                        */
                                    }}
                                >
                                    {images.map(e => {
                                        return(
                                            <img
                                                key={e.imageID}
                                                alt="Game artwork/screenshot"
                                                className="img-fluid"
                                                style={{width: "initial", maxHeight: "450px"}}
                                                src={`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?type=game&imageID=${e.imageID}`}
                                                onError={(i) => (i.target.src = fallbackAvatar)}
                                                delete={props.isEditing ? (
                                                    () => {
                                                        if(props.isEditing) {
                                                            deleteImage(e.imageID);
                                                        }
                                                    }
                                                ) : (
                                                    null
                                                )}
                                            />
                                        );
                                    })}
                                </Carousel>
                            </div>
                        ) : (
                            <div className="text-center">
                                There are no gallery images for this game!
                            </div>
                        )}
                    </div>

                    <div className="col-12 text-center mb-2">
                        <div className="row">
                            {props.isEditing &&
                            <>
                                <div className="col-12">
                                    <ButtonInput
                                        trigger
                                        label="Upload Images"
                                        disabled={!props.isEditing}
                                        set={setImageUpload}
                                        className="editgamebtn my-2"
                                        style={{backgroundColor: '#fbd731'}}
                                    />
                                </div>

                                <div
                                    className="col-12 section-subtitle text-center my-0"
                                    style={{display: 'inline-block', maxWidth: '350px'}}
                                >
                                    * Only 10 images per game. The older images will be replaced by the new ones you upload.
                                </div>
                            </>
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

        {imageUpload && (
            <ImageUploadModal
                title='Game Images'
                show={imageUpload}
                hide={() => setImageUpload(false)}
                saveImage={saveImage}
                saveFormat={'image/jpeg'}
                //multiple={true}
                maxWidth={1920}
                maxHeight={1080}
                /*
                aspectRatios={[
                    {
                        name: '16:9',
                        ratio: Number(16/9)
                    },
                    // {
                    //     name: '16:10',
                    //     ratio: Number(16/10)
                    // }
                ]}
                */
            />
        )}
        </>
    );
}

export function ServiceGalleryModal(props) {
    const windowSize = useWindowSize();

    const [videos, setVideos] = useState([]);
    const [newVideo, setNewVideo] = useState('');

    const [images, setImages] = useState([]);
    const [imageUpload, setImageUpload] = useState(false);

    useEffect(() => {
        updateMedia();
    }, []);

    function updateMedia() {
        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/serviceinfo?service=${props.service}`)
        .then(async (response) => {
            //console.log(response.data);
            setVideos(response.data.media_videos);
            setImages(response.data.media_images);
        });
    }

    function deleteImage(imageID) {
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/service/media/image/delete`, {
            service: props.service,
            imageID: imageID
        }, {
            withCredentials: true
        })
        .then(response => {
            updateMedia();
        })
        .catch(error => {
            //window.alert('An error occurred!');
            window.alert(error.errorMessage);
        });
    }

    function saveImage(e) {
        const formData = new FormData();

        formData.append('image', e);
        formData.append('service', props.service);

        const config = {
            withCredentials: true
        }

        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/service/media/image/replace`, formData, config)
        .then(data => {
            updateMedia();
        });
    }

    function addVideo() {
        const videosCopy = videos;

        const newVideoObj = {
            videoLink: newVideo,
            date: Date.now()
        }

        videosCopy.push(newVideoObj);
        setVideos(videosCopy);

        setNewVideo('');

        saveVideos();
    }

    function deleteVideo(index) {
        var videosCopy = videos;
        videosCopy.splice(index, 1);
        setVideos(videosCopy);
        saveVideos();
    }

    function saveVideos() {
        const body = {
            service: props.service,
            videos: videos
        }

        const config = {
            withCredentials: true
        }

        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/service/media/videos/replace`, body, config)
        .then(data => {
            updateMedia();
        });
    }

	return(
        <>
		<Modal show={props.show} onHide={props.hide}>
			<Modal.Body
				id="gamedetails-modal"
				style={{
                    opacity: 1,
                    justifyContent: 'initial'
                }}
			>
				<Modal.Header
					closeButton
					className='modal-header-position'
				/>

                <div className="row with-scroll">
                    <div className="col-12 mt-5 mb-2 text-center">
                        <h3>Video Gallery</h3>
                    </div>

                    <div className="col-12">
                        {Array.isArray(videos) && videos.length > 0 ? (
                            <div 
								style={
									windowSize.width < 768
									? {maxWidth: '95%', margin: 'auto'}
									: {maxWidth: '75%', margin: 'auto'}
								}
                            >
								<Carousel
									swipeable={true}
									useKeyboardArrows={true}
									showArrows={true}
									showThumbs={true}
									showIndicators={false}
									showStatus={false}
									dynamicHeight={true}
									infiniteLoop={true}
									renderItem={customRenderItem}
									renderThumbs={customRenderThumb}
                                    onClickThumb={(index) => {
                                        /*
                                        if(props.isEditing) {
                                            deleteVideo(index);
                                        }
                                        */
                                    }}
								>
                                    {videos.map((video, index) => {
										return(
                                            <YoutubeSlide
                                                key={video.videoLink}
                                                url={video.videoLink}
                                                delete={props.isEditing ? (
                                                    () => {
                                                        if(props.isEditing) {
                                                            deleteVideo(index);
                                                        }
                                                    }
                                                ) : (
                                                    null
                                                )}
                                            />
										);
									})}
                                </Carousel>
                            </div>
                        ) : (
                            <div className="text-center">
                                There are no gallery videos for this service!
                            </div>
                        )}
                    </div>

                    {props.isEditing && Array.isArray(videos) && videos.length < 3 && (
                        <div className="col-12 mb-4">
                            <div className="row mx-auto" style={{maxWidth: '650px'}}>
                                <div className="col-12">
									<WebsiteInput
									    name="URL"
										placeholder="New Youtube Link"
                                        disabled={!props.isEditing}
                                        ignoreValidation={true}
										initial={''}
										value={newVideo}
                                        set={setNewVideo}
                                        className="input-field-custom mx-0"
									/>
                                </div>
                                <div className="col-12">
									<ButtonInput
										trigger
										label="Add"
										disabled={!props.isEditing || !newVideo}
										set={addVideo}
                                        className="editgamebtn w-100 mt-0"
                                        style={
                                            !props.isEditing || !newVideo
                                            ? {backgroundColor: '#ededed'}
                                            : {backgroundColor: '#fbd731'}
                                        }
									/>
                                </div>
                                <div className="col-12 section-subtitle text-center my-0">
                                    * Only 3 videos per service. If the thumbnail doesn't show,
                                    try to change the format of the YouTube link.
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="col-12 mt-4 mb-2 text-center">
                        <h3>Image Gallery</h3>
                    </div>

                    <div className="col-12">
                        {Array.isArray(images) && images.length > 0 ? (
                            <div 
								style={
									windowSize.width < 768
									? {maxWidth: '95%', margin: 'auto'}
									: {maxWidth: '75%', margin: 'auto'}
								}
                            >
                                <Carousel
									swipeable={true}
									useKeyboardArrows={true}
                                    showArrows={true}
                                    showThumbs={true}
                                    showIndicators={false}
                                    showStatus={false}
                                    dynamicHeight={true}
                                    infiniteLoop={true}
									renderItem={customRenderItem}
									renderThumbs={customRenderThumb}
                                    onClickThumb={(index) => {
                                        /*
                                        if(props.isEditing) {
                                            deleteImage(images[index].imageID);
                                        }
                                        */
                                    }}
                                >
                                    {images.map(e => {
                                        return(
                                            <img
                                                key={e.imageID}
                                                alt="Service artwork/screenshot"
                                                className="img-fluid"
                                                style={{width: "initial", maxHeight: "450px"}}
                                                src={`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?type=service&imageID=${e.imageID}`}
                                                onError={(i) => (i.target.src = fallbackAvatar)}
                                                delete={props.isEditing ? (
                                                    () => {
                                                        if(props.isEditing) {
                                                            deleteImage(e.imageID);
                                                        }
                                                    }
                                                ) : (
                                                    null
                                                )}
                                            />
                                        );
                                    })}
                                </Carousel>
                            </div>
                        ) : (
                            <div className="text-center">
                                There are no gallery images for this service!
                            </div>
                        )}
                    </div>

                    <div className="col-12 text-center mb-2">
                        <div className="row">
                            {props.isEditing &&
                            <>
                                <div className="col-12">
                                    <ButtonInput
                                        trigger
                                        label="Upload Images"
                                        disabled={!props.isEditing}
                                        set={setImageUpload}
                                        className="editgamebtn my-2"
                                        style={{backgroundColor: '#fbd731'}}
                                    />
                                </div>

                                <div
                                    className="col-12 section-subtitle text-center my-0"
                                    style={{display: 'inline-block', maxWidth: '350px'}}
                                >
                                    * Only 10 images per service. The older images will be replaced by the new ones you upload.
                                </div>
                            </>
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

        {imageUpload && (
            <ImageUploadModal
                title='Service Images'
                show={imageUpload}
                hide={() => setImageUpload(false)}
                saveImage={saveImage}
                saveFormat={'image/jpeg'}
                //multiple={true}
                maxWidth={1920}
                maxHeight={1080}
                /*
                aspectRatios={[
                    {
                        name: '16:9',
                        ratio: Number(16/9)
                    },
                    // {
                    //     name: '16:10',
                    //     ratio: Number(16/10)
                    // }
                ]}
                */
            />
        )}
        </>
    );
}
