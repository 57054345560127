import React from "react";
import {Redirect} from "react-router-dom";

import GameActSlider from "./gamedev/activityMainSlider";

//import QUDOServer from "../helpers/QUDOServerConnection";

class UserActivity extends React.Component {
	constructor(props) {
		super(props);
        this.state = {
            isEditing: false
        }
    }

    /*
	componentDidMount() {
        this.updateResources();
	}
    */

    /*
    updateResources = async () => {
        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/resources`, {
            withCredentials: true
        })
        .then(async result => {
            await this.setState((state) => {
                state.resources = [];

                for (let game in result.data) {
                    state.resources[game] = result.data[game]
                }

                return state;
            });
        });
    }
    */

    add = async () => {
        this.setState({
            addGame: true
        });
    }

	render() {
        if(this.state.addGame === true) {
            return <Redirect push to={process.env.REACT_APP_GAMEDEV_ADD_GAME}/>
        }

		return (
            <div className="row position-relative">
                <div className="col-sm-12 text-left" id="title-box">
                    <div id="title">Your Games</div>
                </div>

                <div className="position-absolute " style={{top: "20px", right: "60px"}}>
                    <div id="addgame" onClick={this.add}>ADD GAME</div>
                </div>

                <div className="col-12 gamedevact-main-container" /* style={{height: "100%"}} */>
                    {/*
                    this.state.resources && (
                        <GameActSlider resources={this.state.resources}/>
                    )
                    */}

                    <GameActSlider/>
                </div>
            </div>
		);
	}
}

export default UserActivity;
