import React from "react";
import {Dropdown} from "react-bootstrap";

import QUDOServer from "../../helpers/QUDOServerConnection";

import {MessageModal} from "../components/modals/modals";

const arrowDown = require("../../images/definitive/icn-down.png");
const fallBackAvatar = require("../../images/definitive/icon-avatar.png");

export default class AvatarChoose extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            update: false,
            avatarFilter: "",
            modalMessage: ""
        }
    }

    componentDidMount () {
        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getavailable`)
        .then(result => {
            /*
            //Make QUDO and Blox the first 'games' before continuing
            var sortable = [
                //['QUDO', ['0']] //QUDO default image
            ];

            for(var game in result.data) {
                sortable.push([game, result.data[game]]);
            }

            for(var i = 0; i < sortable.length; i++) {
                if(sortable[i][0] === 'QUDO') {
                    const copy = sortable[0];
                    sortable[0] = sortable[i];
                    sortable[i] = copy;
                }

                if(sortable[i][0] === 'Blox') {
                    const copy = sortable[1];
                    sortable[1] = sortable[i];
                    sortable[i] = copy;
                }
            }

            //console.log(sortable);

            var objSorted = {}

            for(var j = 0; j < sortable.length; j++) {
                const item = sortable[j];
                objSorted[item[0]] = item[1];
            }
            */

            this.setState({
                //images: objSorted,
                images: result.data,
                newImageID: this.props.info.imageID ? this.props.info.imageID : '0'
            });
        })
        .catch(error => {
            //console.log(error);

            this.setState({
                images: {'QUDO': ['0']},
                newImageID: this.props.info.imageID ? this.props.info.imageID : '0'
            });
        });
    }

    filterAvatars = async (event) => {
        //console.log(event);

        await this.setState({
            avatarFilter: Date.now() //This forces the list to reset, avoids bugs
        });

        await this.setState({
            avatarFilter: event
        });

        this.setImageID(this.props.info.imageID);
    }

    setImageID = (id) => {
        this.setState({
            newImageID: id
        });
    }

    availableAvatars = () => { 
        const items = [];

        for(let game in this.state.images) {
            if(!this.state.avatarFilter) {
                for(let id of this.state.images[game]) {
                    items.push(
                        <img
                            src={`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?gamename=${game}&imageID=${id}`}
                            //src={`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?gamename=${game}&imageID=${id}&rand=${Date.now()}`}
                            alt="users avatar"
                            className="rounded-circle col-3 mb-3 img-fluid "
                            style={{opacity: id === this.state.newImageID ? "1" : "0.5", height: "10%"}}
                            onClick={() => this.setImageID(id)}
                            onError={i => i.target.src=fallBackAvatar}
                        />
                    );
                } 
            } else if(game === this.state.avatarFilter) {
                for(let id of this.state.images[game]) {
                    items.push(
                        <img
                            src={`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?gamename=${game}&imageID=${id}`}
                            //src={`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?gamename=${game}&imageID=${id}&rand=${Date.now()}`}
                            alt="users avatar"
                            className="rounded-circle col-3 mb-3 img-fluid"
                            style={{opacity: id === this.state.newImageID ? "1" : "0.5"}}
                            onClick={() => this.setImageID(id)}
                            onError={i => i.target.src=fallBackAvatar}
                        />
                    );
                }
            }
        }

        //console.log(items.length);

        if(items.length > 0) {
            return items;
        } else {
            return(
                <div className="col-12">
                    No avatars available
                </div>
            );
        }
    }

    submit = async (e) => {
        e.preventDefault();

        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/profile/change`, 
        {
            //password: this.state.Password,
            imageID: this.state.newImageID !== this.props.info.imageID ? this.state.newImageID : ""
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
            json: true,
            withCredentials: true
        })
        .then(result => {
            this.setState({
                //isValid: false,
                //Password: "",
                update: true,
                modalMessage: 'Success'
            });
        })
        .catch((error) => {
            this.setState({
                //isValid: false,
                //Password: "",
                update: false,
                modalMessage: error.errorMessage
            });
        });
    }

    render() {
        return(
            <div className="w-100">
                <div style={{fontWeight: "bold", marginBottom: "0.5rem"}}>
                    Choose your avatar
                </div>

                <div className="edit-field col-12 position-relative">
                    <Dropdown>
                        <Dropdown.Toggle as="div">
                            <div className="edit-field-title">
                                Filter
                            </div>
                            <img src={arrowDown} className="edit-profile-dropdown-arrow" alt="arrow down"/>
                            {this.state.avatarFilter ? this.state.avatarFilter : "All"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{width: "100%"}}>
                            <Dropdown.Item id="edit-profile-filter-items" eventKey={""} onSelect={this.filterAvatars}>
                                Show all
                            </Dropdown.Item>
                            {this.state.images && Object.keys(this.state.images).map(i => {
                                return (
                                    <Dropdown.Item
                                        key={i}
                                        eventKey={i}
                                        onSelect={this.filterAvatars}
                                        id="edit-profile-filter-items"
                                    />
                                );
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                <div className="row scroll-50-no-scrollbar" style={{margin: "1rem"}}>
                    {this.availableAvatars()}
                </div>

                <div
                    className={`col-12 edit-profile-save-btn ${(this.state.newImageID !== this.props.info.imageID) ? "" : "disabled"}`}
                    onClick={this.submit}
                >
                    Save
                </div>

				<MessageModal
					show={this.state.modalMessage} 
					message={this.state.modalMessage}
					hide={() => {
                        if(this.state.update) {
                            this.props.fetchNewInfo();
                        }

                        this.setState({
                            update: false,
                            modalMessage: ''
                        });
                    }}
				/>
            </div>
        );
    }
}
