import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import Moment from 'moment-timezone';

import GameDetailsModal from '../components/modals/GameDetailsModal';

const fallBack = require('../../images/fallbackAvatar.png');

export default function LastPlayed(props) {
	const info = useSelector(state => state.info);

	const [timezone, setTimezone] = useState('');
	const [gamesPlayed, setGamesPlayed] = useState([]);
	const [selectedGame, setSelectedGame] = useState('');

	useEffect(() => {
		const tz = Moment.tz.guess();

		//Sort by last played
		const gamesPlayed = info.gamesDetails.sort((a, b) => {
			return new Date(b.lastPlayed).getTime() - new Date(a.lastPlayed).getTime();
		});

		setTimezone(tz);
		setGamesPlayed(gamesPlayed);
	}, []);

	return(
		<>
			<div id='title' className='mb-3'>
				Game Activity
			</div>

			{gamesPlayed.length > 0 ? (
				<div className='row mx-auto' style={{maxWidth: '100%'}}>
					{gamesPlayed.map(game => {
						return(
							<div
								key={game.name}
								className='col-12 mb-2 enable-click'
								onClick={() => setSelectedGame(game.name)}
							>
								<div className='lastplayed-image-container'>
									<img
										alt='Game banner'
										className='game-website-lastplayed-image'
										onError={i => i.target.src = fallBack}
										src={`${process.env.REACT_APP_QUDO_SERVER}/api/images/getbygame?gamename=${game.name}`}
									/>
								</div>
								<div className='lastplayed-textblock text-left'>
									<div className='lastplayed-text-bg'>
										{game.display_name ? game.display_name : game.name}
									</div>
									<div className='lastplayed-text-sm'>
										{game.lastPlayed && (
											<div>
												Last Played: {Moment(new Date(game.lastPlayed)).tz(timezone).fromNow()}
											</div>
										)}
									</div>
								</div>
							</div>
						);
					})}
				</div>
			) : (
				<div /* className='text-center' */>
					Seems like you haven't played anything yet. Your games will show here, so just go play something!
				</div>
			)}

			{selectedGame && (
				<GameDetailsModal
					show={!!selectedGame}
					setShow={setSelectedGame}
					game={selectedGame}
				/>
			)}
		</>
	);
}
