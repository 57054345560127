import React, { useState, useEffect } from "react";
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { Modal, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import { WebsiteInput, TextAreaInput, NameInput, DisplayNameInput } from "../../components/forms/text";
import { DropdownInput } from "../../components/forms/dropdowns";
import { ButtonInput } from "../../components/forms/buttons";
//import { CheckboxInput } from "../../components/forms/checkboxes";
import { /* UploadAPKModal, SuccessModal, FailureModal, */ MessageModal } from "../../components/modals/modals";
import ConfirmModal from "../../components/modals/confirmModal/confirmModal";
import { useDispatch } from "react-redux";
import * as actions from "../../../actions";
//import regexes from "../../../helpers/validationRegexes"

import QUDOServer from '../../../helpers/QUDOServerConnection';

import gameGenreList from '../../../helpers/game-genre-list.json';

const appStoreButton = require('../../../images/marketplaces/app_store_en.svg');
const googlePlayStoreButton = require('../../../images/marketplaces/google_play_store_en.svg');
const steamStoreButton = require('../../../images/marketplaces/steam_store_en.svg');
const itchioStoreButton = require('../../../images/marketplaces/itchio_store_en.svg');
const genericDownloadButton = require('../../../images/marketplaces/generic_download_en.svg');

export default function GameDetailsEdition(props) {
	const history = useHistory();
	const dispatch = useDispatch();

	const game = props.game;
	//console.log(game);

	//const [name, setName] = useState("");
	//const [tempName, setTempName] = useState('');
	//const name = useDebounce(tempName, 250);

	const [displayName, setDisplayName] = useState(game && game.display_name ? game.display_name : '');
	const [website, setWebsite] = useState(game ? game.website : "");
	const [description, setDescription] = useState(game ? game.description : "");

    const [gamePublic, setGamePublic] = useState(game ? game.public : false);
	const [comingSoon, setComingSoon] = useState(game ? game.comingSoon : true);
	const [releaseDate, setReleaseDate] = useState(game ? game.estimatedReleaseDate : '');
	const [gameState, setGameState] = useState(game && game.gameState ? game.gameState : 'alpha');

	const [multiplayer, setMultiplayer] = useState(game && typeof game.multiplayer === 'boolean' ? game.multiplayer : false);
	const [useGamedevSavings, setUseGamedevSavings] = useState(game && typeof game.useGamedevSavings === 'boolean' ? game.useGamedevSavings : false);

	const [editing, setEditing] = useState(game ? false : true);
	//const [showAppID, setShowAppID] = useState(false);

	const [marketplaces, setMarketplaces] = useState(game && Array.isArray(game.marketplaces) ? game.marketplaces : []);
	const [marketplacesModal, setMarketplacesModal] = useState(false);

	const [genres, setGenres] = useState(game && Array.isArray(game.genres) ? game.genres : []);
	const [platforms, setPlatforms] = useState(game && Array.isArray(game.platforms) ? game.platforms : []);

	//const [successUploadAPK, setSuccessUploadAPK] = useState(false);
	//const [failureUploadAPK, setFailureUploadAPK] = useState(false);
	//const [isUploadingAPK, setIsUploadingAPK] = useState();

	const [copied, setCopied] = useState(false);

	//const [invalid, setInvalid] = useState(false);
	//const [errorMessage, setErrorMessage] = useState('');

	//const [successModal, setSuccessModal] = useState(false);
	//const [failureModal, setFailureModal] = useState(false);
	const [update, setUpdate] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
	//const [warningModal, setWarningModal] = useState(false);
	//const [warningModalMessage, setWarningModalMessage] = useState('');

	const [websiteEditing, setWebsiteEditing] = useState(false);
	const [descriptionEditing, setDescriptionEditing] = useState(false);
	const [genreEditing, setGenreEditing] = useState(false);
	const [nameEditing, setNameEditing] = useState(false);

	/*
	useEffect(() => {
		props.setDummyName(name);

		if(!game && name) {
			QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/game/exists?name=${name}`)
			.then(data => {
				if(data.data.exists) {
					setErrorMessage('A game with this name already exists!');
					setInvalid(true);
				} else {
					setInvalid(false);
				}
			});
		}
	}, [name]);
	*/

	useEffect(() => {
		props.toggleEdit(editing);
	}, [editing]);

	function CopyToClipboard(value) {
		var tempInput = document.createElement("input");
		tempInput.value = value;
		document.body.appendChild(tempInput);
		tempInput.select();
		document.execCommand("copy");
		document.body.removeChild(tempInput);

		setCopied(true);
		setTimeout(() => setCopied(false), 3000);
	}

	const finish = () => {
		// if(game) {
			setEditing(false);

			QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/update`,
				{
					gamename: game.name,
					display_name: displayName !== game.display_name ? displayName : null,
					public: gamePublic, // !== game.public ? gamePublic : null,
					comingSoon: comingSoon, // !== game.comingSoon ? comingSoon : null,
					website: website !== game.website ? website : null,
					description: description !== game.description ? description : null,
					multiplayer: multiplayer,
					useGamedevSavings: useGamedevSavings,
					gameState: gameState !== game.gameState ? gameState : null,
					estimatedReleaseDate: releaseDate !== game.estimatedReleaseDate ? releaseDate : null,
					marketplaces: marketplaces ? marketplaces : null,
					genres: genres ? genres : null,
					platforms: platforms ? platforms : null
				},
				{
					withCredentials: true
				}
			)
			.then(async () => {
				//Show pending state message if game needs a state confirmation from the QUDO team
				if(
					(!game.public && gamePublic)
					||
					(game.comingSoon && !comingSoon)
				) {
					setUpdate(true);
					setModalMessage(
						"Success! The QUDO team has received a public state change request from this game that's waiting to be confirmed."
						+ " You will be notified when a decision about your game has been made."
					);
				} else {
					setUpdate(true);
					setModalMessage('Success');
				}
			})
			.catch((error) => {
				setUpdate(false);
				setModalMessage(error.errorMessage);
			});
		// } else {
		// 	if(invalid || !name || name.length < 4) {
		// 		return;
		// 	}

		// 	QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/create`,
		// 		{
		// 			gamename: name,
		// 			display_name: displayName,
		// 			stake: props.stake,
		// 			//maxIncreasesPerBlock: props.increases,
		// 			//maxIncreasesPerBlock: parseInt(600 / parseFloat(props.increases)),
		// 			description: description,
		// 			//website: website
		// 		},
		// 		{
		// 			withCredentials: true
		// 		}
		// 	)
		// 	.then(() => {
		// 		setUpdate(true);
		// 		setModalMessage('Success');
		// 	})
		// 	.catch((error) => {
		// 		setUpdate(false);
		// 		setModalMessage(error.errorMessage);
		// 	});
		// }
	}

	const startEndEditMarketPlaces = () =>
	{
		setEditing(true);
		setMarketplacesModal(!marketplacesModal);
	}

	const changeGenreEditing = () =>
	{
		setEditing(true);
		setGenreEditing(!genreEditing);
	}

	const changeWebsiteEditing = () =>
	{
		setWebsiteEditing(!websiteEditing);
		setEditing(true);
	}

	const changeNameEditing = () =>
	{
		setNameEditing(!nameEditing);
		setEditing(true);
	}

	const changeDescriptionEditing = () =>
	{
		setDescriptionEditing(!descriptionEditing);
		setEditing(true);
	}

	const resetAllEditing = () =>
	{
		setEditing(false);
		setNameEditing(false);
		setGenreEditing(false);
		setWebsiteEditing(false);
	}

	const gameWebsite = editing && websiteEditing ? 
	(
		<Form>
			<WebsiteInput 
				name="Website"
				placeholder="Website"
				disabled={!editing}
				initial={website}
				value={website}
				set={setWebsite}
			/>
		</Form>
	) :
	(
		<div
			className="mb-4 mt-4 col-12 hoverable"
			onClick={() => changeWebsiteEditing()}
		>
			<h1 className="custom-header mb-2">Website</h1>
			<strong>{website}</strong>
		</div>
	);

	const gameName = () =>
	{
		return (
			<>
				<div
					data-tip
					data-for='gameName'
					className="mb-4 mt-4 col-12 hoverable"
					onClick={() => CopyToClipboard(game.name)}
				>
					<h1 className="custom-header mb-2">App Name</h1>
					<strong>{game.name}</strong>
				</div>

				<ReactTooltip id='gameName' type='light'>
					<span>
						{copied ? 'Copied' : 'Click to copy to clipboard'}
					</span>
				</ReactTooltip>
			</>
		);
	}

	const gameDisplayName = () => 
	{
		if (editing && nameEditing)
		{
			if (game)
			{
				return (
					<Form>
						<DisplayNameInput
							type="game"
							initial={displayName}
							value={displayName}
							set={setDisplayName}
							disabled={!editing}
							placeholder="Display name (optional)"
						/>
					</Form>	
				);
			}
			else
			{
				/*
				return (
					<Form>
						<NameInput 
							set={setTempName} 
							disabled={!editing}
							pattern={regexes.gameName}
							//placeholder="Name"
							placeholder="Unique ID"
							invalid={invalid}
							errorMessage={errorMessage}
						/>
					</Form>
				);
				*/
			}
		}
		else
		{
			return (
				<div
					className="mb-4 mt-4 col-12 hoverable"
					onClick={() => changeNameEditing()}
				>
					<h1 className="custom-header mb-2">Display Name</h1>
					<strong>{displayName}</strong>
				</div>
			);
		}
	};

	const gameDescription = () =>
	{
		if (editing && descriptionEditing)
		{
			return (
				<Form>
					<TextAreaInput
						placeholder="Description"
						initial={description}
						value={description}
						disabled={!editing}
						set={setDescription}
						className={"col-12 input-field-custom-tall"}
						maxLength={1000}
					/>
				</Form>
			)	
		}
		else
		{
			return (
				<div className="mb-4 mt-4 col-12 border-bottom hoverable"
					onClick={() => changeDescriptionEditing()}>
					<h1 className="custom-header mb-2">Game Description</h1>
					<p>{description}</p>
				</div>
			)
		}
	}

	const multiplayerOptions = () => {
		return (
			<>
				<div className="mb-4 mt-4 col-12 border-bottom">
					<h1 className="custom-header mb-2">
						Game Type
					</h1>
					<div className="row mb-4">
						<div className="col-10">
							Single player
						</div>
						<div className="col-1">
							<input
								type="checkbox"
								className="my-auto"
								checked={!multiplayer}
								onChange={() => {
									setEditing(true);
									setMultiplayer(!multiplayer);
								}}
							/>
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-10">
							Multiplayer
						</div>
						<div className="col-1">
							<input
								type="checkbox"
								className="my-auto"
								checked={multiplayer}
								onChange={() => {
									setEditing(true);
									setMultiplayer(!multiplayer);
								}}
							/>
						</div>
					</div>
				</div>
			</>
		);
	}

	const gameRewardsOptions = () =>
	{
		return (
			<>
				<div
					data-tip
					data-for='gameRewardsOptions'
					className="mb-4 mt-4 col-12 border-bottom"
				>
					<h1 className="custom-header mb-2">
						Game Rewards
					</h1>
					<div className="row mb-4">
						<div className="col-10">
							Use my pocket change for rewards
						</div>
						<div className="col-1">
							<input
								type="checkbox"
								className="my-auto"
								checked={useGamedevSavings}
								onChange={() => {
									setEditing(true);
									setUseGamedevSavings(!useGamedevSavings);
								}}
							/>
						</div>
					</div>
				</div>

				<ReactTooltip id='gameRewardsOptions' type='light'>
					<div>Use the game developer's pocket change for rewards instead of the game balance.</div>
					<div>Once the pocket change balance is fully spent, the game will default back to its own balance.</div>
				</ReactTooltip>
			</>
		)
	}

	const gamePlatforms = () =>
	{
		const AvailablePlatforms = [
			{key: 'ios', label: 'iOS'},
			{key: 'android', label: 'Android'},
			{key: 'pc', label: 'PC'}
		];

		return (
			<>
				<div className="mt-4 col-12">
					<h1 className="custom-header mb-2">Platforms</h1>
				</div>
				<div className="col-12 mb-4">
					{AvailablePlatforms.map(platform => {
						const platformEnabled = platforms.find(e => e.name === platform.key);

						return(
							<div className="row mt-2">
								<div className="col-10">
									{platform.label}
								</div>
								<div className="col-1">
									<input
										type="checkbox"
										className="my-auto"
										checked={platformEnabled}
										onChange={() => {
											setEditing(true);

											let platformsCopy = JSON.parse(JSON.stringify(platforms));

											if(platformEnabled) {
												platformsCopy = platformsCopy.filter(e => e.name !== platform.key);
											} else {
												platformsCopy.push({name: platform.key});
											}

											setPlatforms(platformsCopy);
										}}
									/>
								</div>
							</div>
						);
					})}
				</div>
				<div className="mb-2 border-bottom"></div>
			</>
		);
	}

	const gameMarketPlaces = () =>
	{
		return (
			<>
				<div className="mb-4 mt-4 col-12">
					<h1 className="custom-header mb-2">Market Places</h1>
				</div>
				<div className="row col-12">
					{Array.isArray(game.marketplaces) && game.marketplaces.length > 0 && (
						game.marketplaces.map(marketplace =>
						{
							let image = genericDownloadButton;

							if (marketplace.platform === 'ios')
							{
								image = appStoreButton;
							}

							if (marketplace.platform === 'android')
							{
								image = googlePlayStoreButton;
							}

							if (marketplace.platform === 'steam')
							{
								image = steamStoreButton;
							}

							if (marketplace.platform === 'itchio')
							{
								image = itchioStoreButton;
							}

							return (
								<div className="col-3">
									<a href={marketplace.url}
										rel="noopener noreferrer" target="_blank">
										<img
											key={marketplace.id}
											alt={marketplace.platform}
											//className={`img-fluid platforms ${image === appStoreButton || image === googlePlayStoreButton ? 'horizontal' : 'square'}`}
											className={`img-fluid platforms horizontal`}
											src={image}
										/>
									</a>
								</div>
							);
						})
					)}
				</div>
				<div className="my-4 mx-3">
					<ButtonInput
						trigger
						label="Edit Marketplaces"
						set={() => startEndEditMarketPlaces()}
						className="editgamebtn w-100"
						style={{backgroundColor: "#fcd902"}}
					/>
				</div>
				<div className="mb-2 border-bottom"></div>
				<MarketplacesModal
					editing={true}
					show={marketplacesModal}
					hide={() => startEndEditMarketPlaces()}
					marketplaces={marketplaces}
					setMarketplaces={setMarketplaces}
				/>
			</>
		)	
	}

	const gameGenres = game && (
		<Form>
			<div className="mt-3 mb-4 col-12">
				<EditGameGenres
					disabled={!editing}
					genres={genres}
					setGenres={setGenres}
					changeEditing={() => changeGenreEditing()}
					genreEditing={genreEditing}
					revealButtons={() => setEditing(true)}
				/>
			</div>
			<div className="mb-2 border-bottom"></div>
		</Form>
	);

	const gameVersions = game && (
		<Form>
			<div className="mt-3 mb-4 col-12">
				<EditGameVersions game={game}/>
			</div>
			<div className="mb-2 border-bottom"></div>
		</Form>
	);

	const gamePublicState = game && (
		<Form>
			<div className="mt-3 mb-2 col-12">
				<EditGamePublicState
					disabled={false}
					gamePublic={gamePublic}
					gameState={gameState}
					setGameState={setGameState}
					setGamePublic={setGamePublic}
					comingSoon={comingSoon}
					setComingSoon={setComingSoon}
					releaseDate={releaseDate}
					setReleaseDate={setReleaseDate}
					pendingRequest={game.publicStatePending}
					changeEditing={(value) => setEditing(value)}
				/>
			</div>
		</Form>
	);

	const sdkDownloadButton = (
		<>
			<div
				data-tip
				data-for='sdkLink'
			>
				<ButtonInput
					trigger
					//label="Download SDK"
					label="Link for QUDO SDK"
					className="editgamebtn w-100"
					style={{backgroundColor: 'white', border: '1px solid black', borderRadius: '5px'}}
					//set={() => window.open('https://bitbucket.org/blockbastards/com.blockbastards.qudosdk/src/master/', '_blank')}
					set={() => CopyToClipboard('http://bitbucket.org/blockbastards/com.blockbastards.qudosdk.git')}
				/>
			</div>

			<ReactTooltip id='sdkLink' type='light'>
				<span>
					{copied ? 'Copied' : 'Click to copy to clipboard'}
				</span>
			</ReactTooltip>
		</>
	);

	/*
	const appId = (
		<>
			<div
				data-tip
				data-for='appID'
				className={`text-center py-2 enable-click col-12`}
				style={{border: '1px solid black', borderRadius: '5px'}}
				onClick={() => {
					if(!showAppID) {
						setShowAppID(!showAppID);
					} else {
						CopyToClipboard(game.appID);
					}
				}}
			>
				{showAppID ? game.appID : 'Click here to reveal the App ID'}
			</div>
			{showAppID && (
				<div
					className="text-center mt-2"
					style={{color: 'red', fontWeight: 'bold', fontSize: '0.9rem'}}
				>
					Don't share the AppID of your games with anyone!
				</div>
			)}
			<ReactTooltip id='appID' type='light'>
				<span>
					{!showAppID ? 'Click here to reveal the App ID' : (copied ? 'Copied' : 'Copy App ID to clipboard')}
				</span>
			</ReactTooltip>
		</>
	);
	*/

	const buttons = editing && (
		<div className="row">
			<div className="col-6">
				<ButtonInput 
					label="Cancel"
					trigger
					set={() => {
						dispatch(actions.fetchUser());
						resetAllEditing();
					}}
					className="editgamebtn w-100"
					style={{border: '1px solid #b2b2b2', backgroundColor: 'white', color: '#f44336'}}
				/>
			</div>
			<div className="col-6">
				<ButtonInput
					label="Save"
					set={finish}
					trigger
					className="editgamebtn w-100"
					style={{border: '1px solid #b2b2b2', backgroundColor: 'white', color: '#00cc69'}}
				/>
			</div>
		</div>
	)

	return (
		<div className="border custom-rounded">
			{gameName()}
			{gameDisplayName()}
			{gameWebsite}
			{gameDescription()}
			{gameRewardsOptions()}
			{multiplayerOptions()}
			{gamePlatforms()}
			{gameMarketPlaces()}
			{gameGenres}
			{gameVersions}
			{gamePublicState}
			{/* appId */}
			{sdkDownloadButton}
			{buttons}

			<MessageModal
				show={modalMessage}
				message={modalMessage}
				hide={() => {
					if(update) {
						dispatch(actions.fetchUser());
						dispatch(actions.getGames());

						if(!game) {
							history.goBack();
						}
					}

					setUpdate(false);
					setModalMessage('');
				}}
			/>
		</div>
	);
}

function EditGameGenres(props) {
	const [newGenre, setNewGenre] = useState('');
	const [newGenreValid, setNewGenreValid] = useState(false);

	const [AvailableGameGenres, setAvailableGameGenres] = useState([]);

	useEffect(() => {
		var genreArr = [];

		//Create a game genre list in the correct format once the component renders
		for(var i = 0; i < gameGenreList.length; i++) {
			genreArr.push({
				key: gameGenreList[i],
				label: gameGenreList[i]
			});
		}

		setAvailableGameGenres(genreArr);
	}, []);

	useEffect(() => {
		if(!props.disabled && newGenre && Array.isArray(props.genres)) {
			const exists = props.genres.find(e => {
				return e.name === newGenre;
			});

			if(exists) {
				setNewGenreValid(false);
			} else {
				setNewGenreValid(true);
			}
		} else {
			setNewGenreValid(false);
		}
	}, [newGenre]);

	function addGenre(name) {
		if(!props.disabled && newGenreValid) {
			var genresCopy = JSON.parse(JSON.stringify(props.genres));

			genresCopy.push({
				name: name
			});

			props.setGenres(genresCopy);
			setNewGenreValid(false);
		}
	}

	function deleteGenre(name) {
		if (window.confirm(`Do you want to delete ${name} from game genres?`)) 
		{
			props.revealButtons();
			var genresCopy = JSON.parse(JSON.stringify(props.genres));

			genresCopy = genresCopy.filter(e => {
				return e.name !== name;
			});

			props.setGenres(genresCopy);
		}
	}

	return (
		<>
			<h1 className="custom-header mb-2">
				Game Genres
			</h1>

			<div className="d-flex justify-content-between mb-2">
				<div className="mt-1">
					TAGS
				</div>
				<div
					className="border add-signal rounded cursor-pointer"
					onClick={props.changeEditing}
				>
					+
				</div>
			</div>

			{Array.isArray(props.genres) && props.genres.length > 0 ?
			(
				<div className="row">
					{props.genres.map(e => {
						return (
							<div key={e.name}>
								<div className="col-4 d-flex justify-content-between mb-2">
									<div className="name genre align-middle pr-2 pl-2">{e.name}</div>
									<div className="border remove-signal cursor-pointer"
										onClick={() => deleteGenre(e.name)}>
										<strong>x</strong>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			) : (
				<div className="row">
					<div className="col-12">
						This game doesn't have any genre information!
					</div>
				</div>
			)}

			{!props.disabled && props.genreEditing && Array.isArray(props.genres) && (
				props.genres.length < 3 ? (
					<div className="row mt-4">
						<div className="col-12">
							{/*}
							<NameInput
								placeholder="New Genre"
								disabled={props.disabled}
								initial={newGenre}
								set={setNewGenre}
								ignoreValidation={true}
								className="input-field-custom mb-1"
							/>
							{*/}

							<DropdownInput
								label="Game Genre"
								disabled={props.disabled}
								initial={'Add new genre...'}
								choices={AvailableGameGenres}
								set={setNewGenre}
							/>
						</div>
						<div className="col-12" style={{marginTop: '-0.9rem'}}>
							<ButtonInput
								trigger
								label="Add"
								disabled={!newGenreValid}
								set={() => addGenre(newGenre)}
								className="editgamebtn w-100 m-0"
								style={newGenreValid ? { backgroundColor: "#fbd731" } : { backgroundColor: "#ededed" }}
							/>
						</div>
						<div className="col-1editing2 section-subtitle text-center my-0">
							* Only 3 game genres per game
						</div>
					</div>
				) : (
					<div className="row mt-2">
						<div className="col-12 section-subtitle my-0">
							Only 3 game genres are allowed for each game.
							{/* Delete one of the current genres if you want to add another one. */}
							If you want to add another game genre, click on one of the current genres
							to delete it and then add another one.
						</div>
					</div>
				)
			)}
		</>
	);
}

function EditGameVersions(props) {
	var maxVersionsToShow = 3;

	const [versions, setVersions] = useState([]);
	const [versionsModal, setVersionsModal] = useState(false);

	useEffect(() => {
		getVersions();
	}, []);

	function getVersions() {
		QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/game/versions?game=${props.game.name}`, {withCredentials: true})
		.then(async data => {
			//console.log(data);

			if(Array.isArray(data.data)) {
				var ordered = await data.data.sort((a, b) => {
					return new Date(b.buildDate).getTime() - new Date(a.buildDate).getTime();
				});

				setVersions(ordered);
			}
		});
	}

	return(
		<>
			<h1 className="custom-header mb-3">
				Game Version Management
			</h1>

			{Array.isArray(versions) && versions.length > 0 ? (
				<>
					<div className="row custom-header mb-2">
						<div className="col-6 text-left">
							Version
						</div>
						<div className="col-6 text-right">
							Build Date
						</div>
					</div>

					{versions.map((version, index) => {
						if(index < maxVersionsToShow) {
							if(version.active) {
								return(
									<div className="row mb-2">
										<div className="col-6 text-left">
											{version.version} {version.platform ? `(${version.platform})` : ''}
										</div>
										<div className="col-6 text-right custom-header">
											{moment(new Date(version.buildDate)).format('DD MMM YYYY @ HH:mm')}
										</div>
										<div className="col-12">
											<div className="border-bottom my-2"></div>
										</div>
									</div>
								);
							} else {
								maxVersionsToShow++;
								return null;
							}
						} else {
							return null;
						}
					})}

					<div>
						<ButtonInput
							trigger
							label="Edit Versions"
							set={() => setVersionsModal(true)}
							className="editgamebtn w-100"
							style={{backgroundColor: "#fcd902"}}
						/>
					</div>

					<GameVersionsModal
						show={versionsModal}
						hide={() => setVersionsModal(false)}
						game={props.game}
						versions={versions}
						getVersions={getVersions}
					/>
				</>
			) : (
				<div className="row">
					<div className="col-12">
						This game doesn't have any versions yet!
					</div>
				</div>
			)}
		</>
	);
}

function GameVersionsModal(props) {
	const [update, setUpdate] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

	const [deleteID, setDeleteID] = useState('');
	const [disableID, setDisableID] = useState('');

	function toggleGameVersion(versionID) {
		QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/version/toggle`, {
			game: props.game.name,
			versionID: versionID
		}, {
			withCredentials: true
		})
		.then((data) => {
			setUpdate(true);
			setModalMessage('Success');
		})
		.catch((error) => {
			setUpdate(false);
			setModalMessage(error.errorMessage);
		})
		.finally(() => {
			setDisableID('');
		});
	}

	function deleteGameVersion(versionID) {
		QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/version/delete`, {
			game: props.game.name,
			versionID: versionID
		}, {
			withCredentials: true
		})
		.then((data) => {
			setUpdate(true);
			setModalMessage('Success');
		})
		.catch((error) => {
			setUpdate(false);
			setModalMessage(error.errorMessage);
		})
		.finally(() => {
			setDeleteID('');
		});
	}

	return(
		<Modal show={props.show} onHide={props.hide}>
			<Modal.Body
				id="gamedetails-modal"
				style={{
                    opacity: 1,
                    justifyContent: 'initial'
				}}
			>
				<Modal.Header
					closeButton
					className='modal-header-position'
				/>

				<div className="with-scroll px-2" style={{overflowX: 'hidden'}}>
					<h3 className="mt-5 mb-4 text-center">
						Game Versions
					</h3>

					<div className="mb-3">
						{Array.isArray(props.versions) && props.versions.length > 0 ? (
							<>
							<div className="row custom-header">
								<div className="col-3 col-lg-2">
									Version
								</div>
								<div className="col-3 col-lg-2">
									Platform
								</div>
								<div className="col-3 col-lg-2">
									SDK Version
								</div>
								<div className="col-3 col-lg-3">
									Build Date
								</div>
							</div>

							{props.versions.map((version) => {
								return(
									<div
										className="row mt-3 border-bottom"
										style={version.active ? {} : {color: '#f44336'}}
									>
										<div className="col-3 col-lg-2 my-auto">
											{version.version}
										</div>
										<div className="col-3 col-lg-2 my-auto">
											{version.platform ? version.platform : 'Unknown'}
										</div>
										<div className="col-3 col-lg-2 my-auto">
											{version.sdkVersion}
										</div>
										<div className="col-3 col-lg-3 my-auto">
											{moment(new Date(version.buildDate)).format('DD MMM YYYY @ HH:mm')}
										</div>
										<div className="col-12 col-lg-3 my-auto">
											{version.active ? (
												<ButtonInput
													trigger
													label="Deprecate"
													set={() => setDisableID(version._id)}
													className="editgamebtn w-100"
													style={{
														color: '#f44336',
														backgroundColor: '#ededed',
														fontWeight: 'bold',
														marginTop: '0.5rem',
														marginBottom: '1rem',
														padding: '0px'
													}}
												/>
											) : (
												<div className='row'>
													<div className='col-6'>
														<ButtonInput
															trigger
															label="Enable"
															set={() => toggleGameVersion(version._id)}
															className="editgamebtn w-100"
															style={{
																color: '#00cc69',
																backgroundColor: '#ededed',
																fontWeight: 'bold',
																marginTop: '0.5rem',
																marginBottom: '1rem',
																padding: '0px'
															}}
														/>
													</div>
													<div className='col-6'>
														<ButtonInput
															trigger
															label="Delete"
															set={() => setDeleteID(version._id)}
															className="editgamebtn w-100"
															style={{
																color: '#f44336',
																backgroundColor: '#ededed',
																fontWeight: 'bold',
																marginTop: '0.5rem',
																marginBottom: '1rem',
																padding: '0px'
															}}
														/>
													</div>
												</div>
											)}
										</div>
									</div>
								);
							})}
							</>
						) : (
                            <div className="text-center">
                                This game doesn't have any versions yet!
                            </div>
						)}
					</div>

					<ConfirmModal
						show={disableID}
						hide={() => setDisableID('')}
						confirm={() => toggleGameVersion(disableID)}
						message="Are you sure you want to deprecate this version?"
					/>

					<ConfirmModal
						show={deleteID}
						hide={() => setDeleteID('')}
						confirm={() => deleteGameVersion(deleteID)}
						message="Are you sure you want to delete this version? This can't be undone!"
					/>

					<MessageModal
						show={modalMessage}
						message={modalMessage}
						hide={() => {
							if(update) {
								props.getVersions();
							}

							setUpdate(false);
							setModalMessage('');
						}}
					/>
				</div>
			</Modal.Body>
		</Modal>
	);
}

function EditGamePublicState(props) {
	const gameStates = [
		"(Alpha) My game can be played but still has a few known issues.",
		"(Beta) My game is close to launch but I don't yet consider it final.",
		"(Release) My game is finished.",
		"(Temporarily Unavailable) My game will be unavailable for a short period of time."
	];

	const gamePublicStates = [
		"For now I'm just trying it out. I don't want my game to be visible.",
		'QUDO is integrated but not yet ready to be published. I am glad to have my game listed as "coming soon" though.',
		"Yes, I am ready! Please review my game so it becomes available."
	];

	const initialState = props.gamePublic ? (props.comingSoon ? 1 : 2) : 0; //The numbers are the index of the possible game public states array
	const [newState, setNewState] = useState(initialState);
	const [showGameStates, setShowGameStates] = useState(initialState === 2);
	const [showPendingReleaseDate, setShowPendingReleaseDate] = useState(initialState === 1);

	useEffect(() => {
		if(initialState === 1 || newState === 1) {
			setShowPendingReleaseDate(true);
		} else {
			setShowPendingReleaseDate(false);
		}

		if(initialState === 2 || newState === 2) {
			setShowGameStates(true);
		} else {
			setShowGameStates(false);
		}
	}, [initialState, newState]);

	useEffect(() => {
		if(newState === 0) {
			props.setGamePublic(false);
			props.setComingSoon(true);
		} else if(newState === 1) {
			props.setGamePublic(true);
			props.setComingSoon(true);
		} else if(newState === 2) {
			props.setGamePublic(true);
			props.setComingSoon(false);
		}
	}, [newState]);

	const changePublicState = (state) => {
		if(!props.disabled) {
			checkIfChanges();
			setNewState(state);
		}
	}

	const changeGameState = (state) => {
		if(!props.disabled) {
			checkIfChanges();
			props.setGameState(state);
		}
	}

	const checkIfChanges = () =>
	{
		props.changeEditing(true);
	}

	if(props.pendingRequest) {
		return(
			<div className="mt-4 mb-3">
				<div className="edit-screen-menu my-2">
					Is your game integration with QUDO ready?
				</div>

				<div className="mb-5">
					The QUDO team has received a public state change request from this game that's waiting to be confirmed.
					You will be notified when a decision about your game has been made.
				</div>
			</div>
		);
	} else {
		return(
			<div className="mt-4 mb-3">
				<div className="edit-screen-menu my-2">
					Is your game integration with QUDO ready?
				</div>

				<div
					className={`row my-2 ${!props.disabled ? 'enable-click ' : ''}`}
					onClick={() => changePublicState(0)}
				>
					<div className="col-1">
						<input
							type="radio"
							name="gamePublicState"
							checked={newState === 0}
							disabled={props.disabled}
						/>
					</div>
					<div className="col-10">
						{gamePublicStates[0]}
					</div>
				</div>

				<div
					className={`row my-2 ${!props.disabled ? 'enable-click ' : ''}`}
					onClick={() => changePublicState(1)}
				>
					<div className="col-1">
						<input
							type="radio"
							name="gamePublicState"
							checked={newState === 1}
							disabled={props.disabled}
						/>
					</div>
					<div className="col-10">
						{gamePublicStates[1]}
					</div>
				</div>

				<div
					className={`row my-2 ${!props.disabled ? 'enable-click ' : ''}`}
					onClick={() => changePublicState(2)}
				>
					<div className="col-1">
						<input
							type="radio"
							name="gamePublicState"
							checked={newState === 2}
							disabled={props.disabled}
						/>
					</div>
					<div className="col-10">
						{gamePublicStates[2]}
					</div>
				</div>

				{showPendingReleaseDate && (
					<div className="mt-3">
						<NameInput
							title="Estimated Release Date"
							placeholder=""
							disabled={props.disabled}
							initial={props.releaseDate}
							set={props.setReleaseDate}
							ignoreValidation={true}
						/>
					</div>
				)}

				{showGameStates && (
					<div className="mx-5">
						<div
							className={`row my-2 ${!props.disabled ? 'enable-click ' : ''}`}
							onClick={() => changeGameState('unavailable')}
						>
							<div className="col-1">
								<input
									type="radio"
									name="gameState"
									checked={props.gameState === 'unavailable'}
									disabled={props.disabled}
								/>
							</div>
							<div className="col-10">
								{gameStates[3]}
							</div>
						</div>

						<div
							className={`row my-2 ${!props.disabled ? 'enable-click ' : ''}`}
							onClick={() => changeGameState('alpha')}
						>
							<div className="col-1">
								<input
									type="radio"
									name="gameState"
									checked={props.gameState === 'alpha'}
									disabled={props.disabled}
								/>
							</div>
							<div className="col-10">
								{gameStates[0]}
							</div>
						</div>

						<div
							className={`row my-2 ${!props.disabled ? 'enable-click ' : ''}`}
							onClick={() => changeGameState('beta')}
						>
							<div className="col-1">
								<input
									type="radio"
									name="gameState"
									checked={props.gameState === 'beta'}
									disabled={props.disabled}
								/>
							</div>
							<div className="col-10">
								{gameStates[1]}
							</div>
						</div>

						<div
							className={`row my-2 ${!props.disabled ? 'enable-click ' : ''}`}
							onClick={() => changeGameState('stable')}
						>
							<div className="col-1">
								<input
									type="radio"
									name="gameState"
									checked={props.gameState === 'stable'}
									disabled={props.disabled}
								/>
							</div>
							<div className="col-10">
								{gameStates[2]}
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

function MarketplacesModal(props) {
	var AvailableMarketplaces = [
		{key: 'ios', label: 'iOS (App Store)'},
		{key: 'android', label: 'Android (Google Play)'},
		{key: 'steam', label: 'Steam'},
		{key: 'itchio', label: 'itch.io'},
		{key: 'other', label: 'Other'}
	];

	const [update, setUpdate] = useState(false);
	const [addMarketplaceModal, setAddMarketplaceModal] = useState(false);

	useEffect(() => {
		if(update) {
			setUpdate(false);
		}
	}, [update]);

	return (
		<>
		<Modal show={props.show} onHide={props.hide}>
			<Modal.Body
				id="gamedetails-modal"
				style={{
                    opacity: 1,
                    justifyContent: 'initial'
				}}
			>
				<Modal.Header
					closeButton
					className='modal-header-position'
				/>
				<div className="with-scroll px-2" style={{overflowX: 'hidden'}}>
					<h3 className="mt-5 mb-4 text-center">
						Marketplaces
					</h3>

					{!update &&
					<div className="mb-3">
						{Array.isArray(props.marketplaces) && props.marketplaces.length > 0
						? props.marketplaces.map((marketplace, index) => {
							const initial = AvailableMarketplaces.find(e => e.key === marketplace.platform);

							return(
								<div key={index} className="row mt-3" style={{borderBottom: '1px solid #ededed'}}>
									<div className="col-12 col-lg-4" style={{cursor: 'initial'}}>
										<DropdownInput
											label="Marketplace"
											disabled={!props.editing}
											choices={AvailableMarketplaces}
											initial={initial ? initial.label : ''}
											set={(key) => {
												marketplace.platform = key;
											}}
										/>
									</div>
									<div className={`col-12 col-lg-${props.editing ? '6' : '8'}`}>
										<WebsiteInput 
											name="URL"
											placeholder="URL"
											disabled={!props.editing}
											ignoreValidation={true}
											initial={marketplace.url}
											value={marketplace.url}
											set={(value) => {
												marketplace.url = value;
											}}
										/>
									</div>
									{props.editing &&
									<div className="col-12 col-lg-2">
										<ButtonInput
											trigger
											label="Remove"
											disabled={!props.editing}
											set={async () => {
												const arr = props.marketplaces;
												arr.splice(index, 1);
												props.setMarketplaces(arr);
												setUpdate(true);
											}}
											className="editgamebtn w-100 mt-0"
											style={{backgroundColor: "#ededed", marginBottom: '1rem', padding: '0px'}}
										/>
									</div>
									}
								</div>
							);
						}) : (
                            <div className="text-center">
                                This game doesn't have any marketplaces yet!
                            </div>
						)}
					</div>
					}

					{props.editing &&
					<div className="row">
						<div className="col-12 col-md-3"></div>
						<div className="col-12 col-md-6">
							<ButtonInput
								trigger
								label="Add Marketplace"
								set={setAddMarketplaceModal}
								className="editgamebtn w-100"
								style={{backgroundColor: "#ededed"}}
							/>
						</div>
						<div className="col-12 col-md-3"></div>
					</div>
					}
				</div>
			</Modal.Body>
		</Modal>

		<AddMarketplaceModal
			editing={props.editing}
			show={addMarketplaceModal}
			hide={() => setAddMarketplaceModal(!addMarketplaceModal)}
			marketplaces={props.marketplaces}
			AvailableMarketplaces={AvailableMarketplaces}
		/>
		</>
	);
}

function AddMarketplaceModal(props) {
	const [newURL, setNewURL] = useState('');
	const [newPlatform, setNewPlatform] = useState(props.AvailableMarketplaces[0].key);
	const [btnDisabled, setBtnDisabled] = useState(true);

	const initial = props.AvailableMarketplaces.find(e => e.key === newPlatform);

	function finish() {
		if(newURL && newPlatform) {
			props.marketplaces.push({
				url: newURL,
				platform: newPlatform
			});
	
			setNewURL('');
			setNewPlatform(props.AvailableMarketplaces[0].key);
	
			props.hide();
		}
	}

	useEffect(() => {
		setBtnDisabled(!newURL || !newPlatform);
	}, [newURL, newPlatform]);

    return (
		<Modal show={props.show} onHide={props.hide}>
			<Modal.Body>
				<Modal.Header
					closeButton
					className='modal-header-position'
				/>
				<div className="row">
					<div className="col-12">
						<div className="mx-auto my-5 section-title text-center">
							Add New Marketplace
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<DropdownInput
							label="Marketplace"
							disabled={!props.editing}
							choices={props.AvailableMarketplaces}
							initial={initial ? initial.label : ''}
							set={setNewPlatform}
						/>
					</div>
					<div className="col-12">
						<WebsiteInput 
							name="URL"
							placeholder="URL"
							disabled={!props.editing}
							ignoreValidation={true}
							//initial={newURL}
							value={newURL}
							set={setNewURL}
						/>
					</div>
					<div className="col-12">
						<ButtonInput
							trigger
							label="Add"
							disabled={btnDisabled}
							set={() => finish()}
							className="editgamebtn w-100"
							style={btnDisabled ? {backgroundColor: "#ededed", cursor: 'initial'} : {backgroundColor: "#ededed"}}
						/>
					</div>
					<div className="col-12 section-subtitle">
						* Only one link per marketplace will be stored (with the exception of 'Other')
					</div>
				</div>
			</Modal.Body>
		</Modal>
    );
}
