import React, { useState, useEffect } from 'react';
import axios from 'axios';

//import Swiper from 'react-id-swiper';
//import 'swiper/css/swiper.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import { faAndroid, faApple } from "@fortawesome/free-brands-svg-icons";

import SwipeableViews from 'react-swipeable-views';
import Pagination from '../components/react-swipeable-views-pagination/Pagination';

import { DropdownInput } from '../components/forms/dropdowns';

import usePrevious from '../../helpers/usePrevious';
import useDebounce from '../../helpers/useDebounce';
import useWindowSize from '../../helpers/useWindowSize';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const fallBack = require('../../images/fallbackAvatar.png');

const unavailableBanner = require('../../images/banners/QUDO_TemporarilyUnavailable.png');
const comingSoonBanner = require('../../images/banners/QUDO_ComingSoon.png');
const alphaBanner = require('../../images/banners/QUDO_Alpha.png');
const betaBanner = require('../../images/banners/QUDO_Beta.png');
const newBanner = require('../../images/banners/QUDO_New.png');

export default function GamesList({ games, showDetails }) {
    //const windowSize = useWindowSize();
    const windowSize = useDebounce(useWindowSize(), 250);
    const previousWindowSize = usePrevious(windowSize);

    const [platform, setPlatform] = useState('');
    const previousPlatform = usePrevious(platform);

    const [toRender, setToRender] = useState([]);
    const [page, setPage] = useState(0);
    const [colNr, setColNr] = useState(1);
    const [colSize, setColSize] = useState();

    const [shouldRender, setShouldRender] = useState(false);

    const [availableGames, setAvailableGames] = useState([]);
    const [upcomingGames, setUpcomingGames] = useState([]);

    useEffect(() => {
        /*
            The window size objects need to be passed to the function as parameters or the function will use the previous values.
            I have no idea why this happens, I tried everything including setting timeouts and nothing would fix this sync problem.
            I wasted way too much time on this...
        */
        setUpcomingGames([]);
        setAvailableGames([]);

        const upcomGames = [];
        const availGames = [];
        games.map(game => {
            if (game.comingSoon || game.gameState === 'unavailable') {
                upcomGames.push(game);
            } else {
                if (game.newGame) {
                    availGames.unshift(game);
                } else {
                    availGames.push(game);
                }
            }
        });
        setUpcomingGames(upcomGames);
        setAvailableGames(availGames);

        getOptions(windowSize, previousWindowSize);
    }, [games, windowSize, previousWindowSize, platform]);

    //Get the nr of columns to render per page and the size of each bootstrap col
    function getOptions(windowSize, previousWindowSize) {
        //Only re-render if the window size actually changes or at least one of the window size objects is undefined
        var reRender = false;

        if (
            !windowSize || !windowSize.width
            || !previousWindowSize || !previousWindowSize.width
            || windowSize.width !== previousWindowSize.width
            || platform !== previousPlatform
        ) {
            reRender = true;
        }

        if (
            availableGames.length > 0
            && (!shouldRender || reRender)
            && (windowSize && windowSize.width)
        ) {
            // if(windowSize.width <= 576) {
            //     setColNr(1);
            //     setColSize(12);
            // } else
            if (windowSize.width < 768) {
                setColNr(2);
                setColSize(6);
            }
            // else if(windowSize.width < 900) {
            //     setColNr(1);
            //     setColSize(12);
            // } else if(windowSize.width < 992) {
            //     setColNr(2);
            //     setColSize(6);
            // }
            else if (windowSize.width < 1400) {
                setColNr(3);
                setColSize(4);
            } else {
                setColNr(4);
                setColSize(3);
            }

            renderGamePages();
        }
    }

    function renderGamePages() {
        const maxLines = 2;

        var toRender = [];
        var gameCounter = 0;
        var pageCounter = 1;

        setShouldRender(false);

        availableGames.map((game, index) => {
            if (platform) {
                if (Array.isArray(game.platforms)) {
                    const renderGame = game.platforms.find(e => {
                        return e.name === platform;
                    });

                    if (!renderGame) {
                        return;
                    }
                } else {
                    return;
                }
            }

            if (gameCounter < (colNr > maxLines ? maxLines : colNr) * colNr * pageCounter) {
                if (!toRender[pageCounter - 1]) {
                    toRender[pageCounter - 1] = [];
                }

                toRender[pageCounter - 1].push(
                    renderGameCard(game, gameCounter)
                );
            } else {
                pageCounter++;

                if (!toRender[pageCounter - 1]) {
                    toRender[pageCounter - 1] = [];
                }

                toRender[pageCounter - 1].push(
                    renderGameCard(game, gameCounter)
                );
            }

            gameCounter++;
        });

        setToRender(
            toRender.map((item, index) => {
                return (
                    <div key={index} className="row mx-0">
                        {item}
                    </div>
                );
            })
        );

        if (page > toRender.length) {
            setPage(0);
        }

        setTimeout(() => {
            setShouldRender(true);
        }, 250);
    }

    function renderGameCard(i, index) {
        var label = null;
        var altText = '';
        var customStyle = {};

        if (i.comingSoon) {
            label = comingSoonBanner;
            altText = 'Game coming soon...';
            customStyle = { maxWidth: '300px' };
        } else {
            if (i.newGame) {
                label = newBanner;
                altText = 'New game';
            } else if (i.gameState === 'unavailable') {
                label = unavailableBanner;
                altText = 'Game is temporarily unavailable';
            } else if (i.gameState === 'alpha') {
                label = alphaBanner;
                altText = 'Game in alpha state';
            } else if (i.gameState === 'beta') {
                label = betaBanner;
                altText = 'Game in beta state';
            } // else if (i.gameState === 'stable') {
            //     if (i.newGame) {
            //         label = newBanner;
            //         altText = 'New game';
            //     }
            // }
        }

        return (
            <div
                key={i.name}
                className={`col-${colSize} position-relative my-1 enable-click`}
                onClick={() => {
                    //console.log(i);
                    showDetails(i.name);
                }}
            >
                <div className="tw-image-container">
                    <img
                        alt={i.display_name ? i.display_name : i.name}
                        src={i.imageURL ? i.imageURL : `${process.env.REACT_APP_QUDO_SERVER}/api/images/getbygame?gamename=${i.name}`}
                        onError={i => i.target.src = fallBack}
                        className="game-website-tw-image"
                    />

                    {label && (
                        <img
                            src={label}
                            alt={altText}
                            className="game-state-banner"
                            style={customStyle}
                        />
                    )}
                </div>

                <div className="games-textblock text-left">
                    <div className="games-text-bg">
                        {i.display_name ? i.display_name : i.name}
                    </div>
                    <div className="games-text-sm" style={{ fontSize: '1.25rem' }}>
                        {/* {i.gamedev.display_name ? i.gamedev.display_name : i.gamedev.username} */}
                        {Array.isArray(i.platforms) && i.platforms.map((e, index) => {
                            if (e.name === 'ios') {
                                return (
                                    <div className='mr-2' style={{ display: 'inline-block' }} key={index}>
                                        <FontAwesomeIcon icon={faApple} />
                                    </div>
                                );
                            } else if (e.name === 'android') {
                                return (
                                    <div className='mr-2' style={{ display: 'inline-block' }} key={index}>
                                        <FontAwesomeIcon icon={faAndroid} />
                                    </div>
                                );
                            } else if (e.name === 'pc') {
                                return (
                                    <div className='mr-2' style={{ display: 'inline-block' }} key={index}>
                                        <FontAwesomeIcon icon={faDesktop} />
                                    </div>
                                );
                            }
                        })}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="main-inner-container mt-2 mb-4">
                <div className="row">
                    <div className="col-12 col-sm-9 mb-2 main-inner-title">
                        Available Games

                        {availableGames.length > 0 && (
                            <span style={{ fontSize: '1.25rem', marginLeft: '3px' }}>
                                ({availableGames.length})
                            </span>
                        )}
                    </div>
                    <div className='col-12 col-sm-3'>
                        <DropdownInput
                            label='Platform'
                            initial='All'
                            set={setPlatform}
                            choices={[
                                { key: '', label: 'All' },
                                { key: 'ios', label: 'iOS' },
                                { key: 'android', label: 'Android' },
                                { key: 'pc', label: 'PC' }
                            ]}
                        />
                    </div>
                </div>

                {shouldRender && availableGames.length > 0 ? (
                    <>
                        <SwipeableViews
                            //enableMouseEvents={true}
                            index={page}
                            onSwitching={pageNr => setPage(pageNr)}
                        >
                            {toRender}
                        </SwipeableViews>

                        <Pagination
                            className='text-center mt-4'
                            style={{}} //Empty to override default style
                            dots={toRender.length}
                            index={page}
                            onChangeIndex={pageNr => setPage(pageNr)}
                        />
                    </>
                ) : (
                    null
                )}

            </div>
            {upcomingGames.length > 0 &&
                <UpcomingGamesList ugames={upcomingGames} showDetails={showDetails}
                    windowSize={windowSize} />
            }
        </>
    );
}

function UpcomingGamesList({ ugames, showDetails, windowSize }) {
    const previousWindowSize = usePrevious(windowSize);
    const [page, setPage] = useState(0);
    const [toRender, setToRender] = useState([]);

    const [shouldRender, setShouldRender] = useState(false);

    const [colNr, setColNr] = useState(8);
    const colSize = 2;

    useEffect(() => {
        if (ugames.length > 0) {
            renderGamePages();
        }
    }, [ugames, colNr]);

    useEffect(() => {
        if (windowSize.width < 500) {
            setColNr(3);
        } else if (windowSize.width < 720) {
            setColNr(4);
        } else if (windowSize.width < 990) {
            setColNr(5);
        } else if (windowSize.width < 1040) {
            setColNr(4);
        } else if (windowSize.width < 1150) {
            setColNr(5);
        } else if (windowSize.width < 1490) {
            setColNr(6);
        } else {
            setColNr(8);
        }
    }, [windowSize]);

    function renderGamePages() {
        const maxLines = 1;

        var toRender = [];
        var gameCounter = 0;
        var pageCounter = 1;

        setShouldRender(false);

        ugames.map((game, index) => {
            if (gameCounter < ((colNr > maxLines ? maxLines : colNr) * colNr * pageCounter)) {
                if (!toRender[pageCounter - 1]) {
                    toRender[pageCounter - 1] = [];
                }

                toRender[pageCounter - 1].push(
                    renderGameCard(game, gameCounter)
                );
            } else {
                pageCounter++;

                if (!toRender[pageCounter - 1]) {
                    toRender[pageCounter - 1] = [];
                }

                toRender[pageCounter - 1].push(
                    renderGameCard(game, gameCounter)
                );
            }

            gameCounter++;
        });

        setToRender(
            toRender.map((item, index) => {
                return (
                    <div key={index} className="row mx-0">
                        {item}
                    </div>
                );
            })
        );

        if (page > toRender.length) {
            setPage(0);
        }

        setTimeout(() => {
            setShouldRender(true);
        }, 250);
    }

    function renderGameCard(i, index) {
        const tooltip = (
            <Tooltip id="tooltip">{(i.display_name && i.display_name !== '') ? i.display_name : i.name}</Tooltip>
        );
        return (
            <div className='col' key={'gameCard' + index}>
                <div
                    key={i.name}
                    className={`mx-auto position-relative enable-click`}
                    style={{ width: '70px' }}
                    onClick={() => {
                        showDetails(i.name);
                    }}
                >
                    <OverlayTrigger rootClose placement="bottom" overlay={tooltip}>
                        {({ ref, ...triggerHandler }) => (
                            <div {...triggerHandler} className="mini-tw-image-container">
                                <img
                                    ref={ref}
                                    alt={i.display_name ? i.display_name : i.name}
                                    src={i.imageURL ? i.imageURL : `${process.env.REACT_APP_QUDO_SERVER}/api/images/getbygame?gamename=${i.name}`}
                                    onError={i => i.target.src = fallBack}
                                    className="mini-game-website-tw-image"
                                />
                            </div>
                        )}
                    </OverlayTrigger>
                </div>
            </div>
        );
    }

    return (

        <div className='pb-4 px-5'>
            <div className="row">
                <div className="col-12 col-sm-9 mb-2 main-inner-title">
                    Upcoming Games

                    {ugames.length > 0 && (
                        <span style={{ fontSize: '1.25rem', marginLeft: '3px' }}>
                            ({ugames.length})
                        </span>
                    )}
                </div>
            </div>
            {shouldRender && ugames.length > 0 ? (
                <div className={(windowSize.width < 580) ? '' : 'mx-5'}>
                    <SwipeableViews
                        //enableMouseEvents={true}
                        index={page}
                        onSwitching={pageNr => setPage(pageNr)}
                        slideClassName='d-flex justify-content-center'
                    >
                        {toRender}
                    </SwipeableViews>

                    <Pagination
                        className='text-center mt-4'
                        style={{}} //Empty to override default style
                        dots={toRender.length}
                        index={page}
                        onChangeIndex={pageNr => setPage(pageNr)}
                    />
                </div>
            ) : (
                null
            )}
        </div>
    );
}